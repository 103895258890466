import React, { useEffect, useState } from "react";
import { useParams, Redirect, useHistory } from "react-router-dom/";
import {
  Typography,
  Row,
  Col,
  Card,
  Button,
  Skeleton,
  Form,
  Select,
  Input,
  DatePicker,
  Radio,
  Switch,
  Flex,
  Modal,
  List,
} from "antd";
import dayjs from "dayjs";
import { axios_json } from "../axios";
import responseHandleSrv from "../services/responseHandleSrv";
import { authHeader } from "../services/auth-header";
import { getCurrentUser } from "../services/auth";
import moment from "moment/moment";
import { values } from "draft-js/lib/DefaultDraftBlockRenderMap";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import { DeleteOutlined } from "@ant-design/icons";

function SegementDetail() {
  const { Title } = Typography;
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  let params = useParams();
  let cust_id = params.customerId;
  const [loadings, setLoadings] = useState(false);
  const [preload, setPreload] = useState(false);
  const [customerId, setCustomerId] = useState(0);
  const dateFormat = "DD/MM/YYYY hh:mm:ss";
  const [form] = Form.useForm();
  const [data, setData] = useState({});
  const [formBlocks, setFormBlocks] = useState([{ id: 1 }]);
  const [conditionBlocks, setConditionBlocks] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpenCondition, setIsOpenCondition] = useState(false);
  const [conditionType, setConditionType] = useState("");
  const [birthdayValue, setBirthdayValue] = useState();
  const [birthdaytype, setBirthdayType] = useState("");
  const [eleId, setEleId] = useState(1);
  const [conditionId, setConditionId] = useState(1);
  const history = useHistory();

  useEffect(() => {
    if (params.segmentId === "new") {
      console.log(`add new segment`);
    } else {
      setCustomerId(params.customerId);
      getData();
    }
  }, []);

  const getData = () => {
    (async () => {
      // setIsLoading(true)
      setPreload(true);
      await axios_json
        .get(`/api/customer/search/by-id/${cust_id}`, {
          headers: authHeader(),
        })
        .then((response) => {
          if (response.data.success) {
            let res = response.data.data;
            console.log("res", res);
            setData(res);

            //(res);
          }
          setPreload(false);
        })
        .catch((err) => {
          setPreload(false);
          responseHandleSrv.handleError(err);
        });
    })();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = async (values) => {
    console.log("onFinish", values);
    let countOr = 0;
    let attrs = [];
    let inxAttr = 0;
    let opts = [];
    let inxOpt = 0;
    let vals = [];
    let inxVal = 0;
    let conditions = [];
    Object.keys(values).map((key, index) => {
      if (key.indexOf("attribute_") !== -1) {
        attrs[inxAttr] = values[key];
        inxAttr++;
      }
    });
    Object.keys(values).map((key, index) => {
      if (key.indexOf("operator_") !== -1) {
        opts[inxOpt] = values[key];
        inxOpt++;
      }
    });
    Object.keys(values).map((key, index) => {
      if (key.indexOf("value_") !== -1) {
        vals[inxVal] = values[key];
        inxVal++;
      }
    });
    for (let i = 0; i < attrs.length; i++) {
      conditions.push({
        attribute: attrs[i],
        operator: opts[i],
        value: vals[i],
      });
    }
    let payload = {
      name: values.name,
      alias: values.alias,
      description: values.description,
      refresh: values.refresh === true ? 1 : 0,
      is_active: values.active === true ? 1 : 0,
      conditions: [
        {
          type: "date",
          fields: conditions,
        },
      ],
    };
    console.log(JSON.stringify(payload));
    await addNew(payload);
  };
  const addNew = async (payload) => {
    setLoadings(true);
    (async () => {
      await axios_json
        .post(`/api/segment/create`, payload, {
          headers: authHeader(),
        })
        .then((response) => {
          if (response.data.success) {
            responseHandleSrv.handleSuccess(response);
          }
          setLoadings(false);
        })
        .catch((err) => {
          setLoadings(false);
          responseHandleSrv.handleError(err);
        })
        .finally(() => {
          history.push("/segmentation");
        });
    })();
  };

  const FormBlock = ({ id, onDelete, blockId, conditionType, checked , length , itemId  }) => (
    <>
      {(conditionType === "demographic" ||
        conditionType === "meetEvents" ||
        conditionType === "engagedWithCampaign") && (
        <>
          <Flex align="center">
            {!checked ? (<>
              <Col span={4}>
              <Form.Item
                name={`data_period_${id}`}
                label={`data_period_${id}`}
                labelCol={{
                  span: 24,
                }}
              >
                <Select
                  placeholder="data_period"
                  optionFilterProp="label"
                  //onChange={onChange}
                  //onSearch={onSearch}
                  options={[
                    {
                      value: "1",
                      label: "1",
                    },
                    {
                      value: "2",
                      label: "2",
                    },
                    {
                      value: "3",
                      label: "3",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name={`period_unit_${id}`}
                label={`period_unit_${id}`}
                labelCol={{
                  span: 24,
                }}
              >
                <Select
                  placeholder="data_period"
                  optionFilterProp="label"
                  //onChange={onChange}
                  //onSearch={onSearch}
                  options={[
                    {
                      value: "mounths",
                      label: "mounths",
                    },
                    {
                      value: "days",
                      label: "days",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            </>) : (<>
            <Col span={8}>
              <RangePicker />
            </Col>
            </>)}
            
            <Col span={12}>
              <Flex>
                <Switch
                  checked={checked}
                  onChange={(checked) => handleSwitchChange(checked, blockId)}
                />
                <div style={{ margin: "0 0 0 10px" }}>Date Range</div>
              </Flex>
            </Col>
          </Flex>
          <Flex gap={20} justify="space-between">
            <Col span={12}>
              <Form.Item
                name={`type_${id}`}
                initialValue={conditionType}
                hidden={true}
              >
                <Input type="text" />
              </Form.Item>
              <Form.Item
                name={`attribute_${id}`}
                label={`Attribute_${id}`}
                labelCol={{
                  span: 24,
                }}
              >
                <Select
                  showSearch
                  placeholder="Select a Attribute"
                  optionFilterProp="label"
                  //onChange={onChange}
                  //onSearch={onSearch}
                  options={[
                    {
                      value: "birthdate",
                      label: "Birthdate",
                    },
                    {
                      value: "create_date",
                      label: "Registration Date",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name={`Operator_${id}`}
                label={`Operator_${id}`}
                labelCol={{
                  span: 24,
                }}
              >
                <Select
                  placeholder="Select a Operator "
                  optionFilterProp="label"
                  //onChange={onChange}
                  //onSearch={onSearch}
                  options={[
                    {
                      value: "All",
                      label: "All",
                    },
                    {
                      value: "Operator 1",
                      label: "Operator 1",
                    },
                    {
                      value: "Operator 2",
                      label: "Operator 2",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name={`Value_${id}`}
                label={`Value_${id}`}
                labelCol={{
                  span: 24,
                }}
              >
                <Input placeholder="Value" />
              </Form.Item>
            </Col>
            <Col span={2}>
              {formBlocks.length > 1 && (
                <Button type="primary" danger onClick={() => onDelete(id)}>
                  Delete
                </Button>
              )}
            </Col>
          </Flex>
        </>
      )}
      {conditionType === "date" && (
        <>
          <Flex align="center">
            {!checked ? (<>
              <Col span={4}>
              <Form.Item
                name={`data_period_${id}`}
                label={`data_period_${id}`}
                labelCol={{
                  span: 24,
                }}
              >
                <Select
                  placeholder="data_period"
                  optionFilterProp="label"
                  //onChange={onChange}
                  //onSearch={onSearch}
                  options={[
                    {
                      value: "1",
                      label: "1",
                    },
                    {
                      value: "2",
                      label: "2",
                    },
                    {
                      value: "3",
                      label: "3",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name={`period_unit_${id}`}
                label={`period_unit_${id}`}
                labelCol={{
                  span: 24,
                }}
              >
                <Select
                  placeholder="data_period"
                  optionFilterProp="label"
                  //onChange={onChange}
                  //onSearch={onSearch}
                  options={[
                    {
                      value: "mounths",
                      label: "mounths",
                    },
                    {
                      value: "days",
                      label: "days",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            </>) : (<>
            <Col span={8}>
              <RangePicker />
            </Col>
            </>)}
            
            <Col span={12}>
              <Flex>
                
                <Switch
                  checked={checked}
                  onChange={(checked) => handleSwitchChange(checked, blockId)}
                />
                <div style={{ margin: "0 0 0 10px" }}>Date Range</div>
              </Flex>
            </Col>
          </Flex>
          <Flex gap={20} justify="space-between">
            <Col span={12}>
              <Form.Item
                name={`type_${id}`}
                initialValue={conditionType}
                hidden={true}
              >
                <Input type="text" />
              </Form.Item>
              <Form.Item
                name={`attribute_${id}`}
                label={`Attribute`}
                labelCol={{
                  span: 24,
                }}
              >
                <Select
                  showSearch
                  placeholder="Select a Attribute"
                  optionFilterProp="label"
                  //onChange={onChange}
                  //onSearch={onSearch}
                  options={[
                    {
                      value: "birthdate",
                      label: "Birthdate",
                    },
                    {
                      value: "create_date",
                      label: "Registration Date",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name={`operator_${id}`}
                label={`Date Unit`}
                labelCol={{
                  span: 24,
                }}
              >
                <Select
                  placeholder="Select a Operator "
                  optionFilterProp="label"
                  onChange={onChangebirthday}
                  //onSearch={onSearch}
                  options={[
                    {
                      value: "yy",
                      label: "Year",
                    },
                    {
                      value: "mm",
                      label: "Month",
                    },
                    {
                      value: "dd",
                      label: "Day",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name={`value_${id}`}
                label={`Value`}
                labelCol={{
                  span: 24,
                }}
              >
                <Select
                  placeholder={` Select a ${birthdaytype}`}
                  //onChange={handleYearChange}
                  //value={selectedValue}
                >
                  {birthdayValue.map((data) => (
                    <Option key={data} value={data}>
                      {data}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {length > 1 && (
               <Button
               type="primary"
               danger
               onClick={() => onDelete(itemId, blockId)}
               style={{ margin: "30px 0 0 0" }}
             >
               Delete {itemId}
             </Button>
            )}
           
          </Flex>
        </>
      )}
    </>
  );

  const ConditionBlock = ({ id, type, item , checked }) => {
    return (
      <>
        <Card title="Segment Conditions" style={{ margin: "20px 0 0 0" }}>
          <Flex justify="space-between">
            <h3>
              {type} # {id}
            </h3>
            <Button
              danger
              type="dashed"
              icon={<DeleteOutlined />}
              onClick={() => deleteCondition(id)}
            ></Button>
          </Flex>

          {item.map((block) => (
            <FormBlock
              key={`${type}_${block.conditionId}`}
              id={`${type}_${block.conditionId}`}
              itemId={block.conditionId}
              item={block.conditions}
              checked={checked}
              blockId={id}
              length={item.length}
              onDelete={deleteFormBlock}
              conditionType={type}
            />
          ))}

          <Flex gap={20} justify="space-between">
            <Button type="dashed" block onClick={() => cloneFormBlock(id)}>
              Add Rule {id}
            </Button>
          </Flex>
        </Card>
      </>
    );
  };

  const cloneFormBlock = (id) => {
    console.log("cloneFormBlock");
    //console.log(conditionBlocks)
    //console.log(id)
    const conditionPrev = [...conditionBlocks];
    let block = conditionPrev.find((a) => a.id === id);
    let index = block.conditions.length;
    let conditionsList = {
      conditionId: index + 1,
    };
    block.conditions.push(conditionsList);
    setConditionBlocks(conditionPrev);
    //setEleId(eleId + 1);
    //setFormBlocks([...formBlocks, { id: eleId }]);
  };

  const deleteFormBlock = (id, conditionId) => {
    console.log("deleteFormBlock");
    console.log(conditionId)
    console.log(id)
    const conditionPrev = [...conditionBlocks];
    let condition = conditionPrev.filter((block) => block.id === conditionId);
    let newlist = condition[0].conditions.filter(
      (item) => item.conditionId !== id
    );
    if (newlist.length > 0) {
      condition[0].conditions = newlist;
      setConditionBlocks(conditionPrev);
    }

    //setFormBlocks(formBlocks.filter((block) => block.id !== id));
  };
  const deleteCondition = (id) => {
    setConditionBlocks(conditionBlocks.filter((block) => block.id !== id));
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const selectType = (type) => {
    setConditionId(conditionId + 1);
    setConditionType(type);
    setConditionBlocks([
      ...conditionBlocks,
      {
        id: conditionId,
        type: type,
        checked: false,
        conditions: [{ conditionId: 1 }],
      },
    ]);
    console.log(conditionBlocks);

    setEleId(eleId + 1);
    console.log(`Select type ${type}`);
    if (type === "date") {
      onChangebirthday("yy");
    }
    setIsModalOpen(false);
    setIsOpenCondition(true);
    // if (type === "date") {
    //   setEleId(eleId + 1);
    //   console.log(`Select type ${type}`);
    //   onChangebirthday("yy");
    //   setConditionType(type);
    //   setIsModalOpen(false);
    //   setIsOpenCondition(true);
    // }
  };

  const onChangebirthday = (type) => {
    console.log(`On changebirthday ${type}`);
    const years = Array.from({ length: 2024 - 2000 + 1 }, (_, i) => 2000 + i);
    const months = Array.from({ length: 12 - 1 + 1 }, (_, i) => 1 + i);
    const days = Array.from({ length: 31 - 1 + 1 }, (_, i) => 1 + i);
    setBirthdayType(type);
    if (type === "yy") {
      console.log("type year");
      setBirthdayValue(years);
    } else if (type === "mm") {
      setBirthdayValue(months);
    } else if (type === "dd") {
      setBirthdayValue(days);
    }
  };

  const handleSwitchChange = (checked, id) => {
    console.log(`On switch change ${checked}`)
    const updatedItems = conditionBlocks.map((item) =>
      item.id === id ? { ...item, checked } : item
    );
    console.log(updatedItems);
    setConditionBlocks(updatedItems);
    //setItems(updatedItems); // Update state with new values
  };

  const conditionTypes = [
    {
      title: "DATE",
      description:
        "Trigger customer who has attributes which meet a condition grouping by Day / Month / Year.",
      type: "date",
    },
    {
      title: "DEMOGRAPHICS",
      description:
        "Trigger customers who has value of the selected demographic attribute which meet the condition.",
      type: "demographic",
    },
    {
      title: "ENGAGED WITH CAMPAIGN",
      description: "Trigger customers who has engaged the selected campaign.",
      type: "engagedWithCampaign",
    },
    {
      title: "MEET EVENTS",
      description: "Trigger customers who has even which meet the condition",
      type: "meetEvents",
    },
  ];

  return (
    <>
      <Row xs={24} xl={24}>
        <Col xs={24} xl={24}>
          <Card
            title="Segment"
            extra={[
              <Button
                type="primary"
                loading={loadings}
                form="frmsegment"
                htmlType="submit"
              >
                Save
              </Button>,
            ]}
          >
            <div>
              {preload ? (
                <Skeleton active />
              ) : (
                <>
                  <Form
                    {...{ labelCol: { span: 4 }, wrapperCol: { span: 20 } }}
                    form={form}
                    name="frmsegment"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                  >
                    <Row justify="center" gutter={[0, 10]}>
                      <Col span={22}>
                        <div>
                          <Form.Item
                            name="name"
                            label={
                              <label
                                style={{ fontWeight: "bold", fontSize: "14px" }}
                              >
                                Name
                              </label>
                            }
                            rules={[
                              {
                                required: true,
                                message: "fill name",
                              },
                            ]}
                          >
                            <Input placeholder="Name" />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={22}>
                        <div>
                          <Form.Item
                            name="alias"
                            label={
                              <label
                                style={{ fontWeight: "bold", fontSize: "14px" }}
                              >
                                alias
                              </label>
                            }
                            rules={[
                              {
                                required: true,
                                message: "please fill alias",
                              },
                            ]}
                          >
                            <Input placeholder="alias" />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={22}>
                        <div>
                          <Form.Item
                            name="description"
                            label={
                              <label
                                style={{ fontWeight: "bold", fontSize: "14px" }}
                              >
                                description
                              </label>
                            }
                            rules={[
                              {
                                required: false,
                                message: "description",
                              },
                            ]}
                          >
                            <Input.TextArea rows={6} />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={22}>
                        <div>
                          <Form.Item
                            name="active"
                            label={
                              <label
                                style={{ fontWeight: "bold", fontSize: "14px" }}
                              >
                                active
                              </label>
                            }
                            valuePropName="checked"
                            initialValue={true}
                          >
                            <Switch defaultChecked />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={22}>
                        <div>
                          <Form.Item
                            name="refresh"
                            label={
                              <label
                                style={{ fontWeight: "bold", fontSize: "14px" }}
                              >
                                refresh
                              </label>
                            }
                            valuePropName="checked"
                            initialValue={false}
                          >
                            <Switch />
                          </Form.Item>
                        </div>
                      </Col>

                      <Col span={22}>
                        <Card>
                          <Button type="dashed" block onClick={showModal}>
                            Add Segement Conditions
                          </Button>
                        </Card>
                      </Col>

                      {isOpenCondition && (
                        <Col span={22}>
                          {conditionBlocks.map((condition) => (
                            <ConditionBlock
                              key={condition.id}
                              id={condition.id}
                              type={condition.type}
                              checked={condition.checked}
                              item={condition.conditions}
                            />
                          ))}
                        </Col>
                      )}
                    </Row>
                  </Form>
                </>
              )}
            </div>
          </Card>
        </Col>
      </Row>
      <Modal
        title="Select Condition Type"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
      >
        <Row gutter={[16, 24]}>
          {conditionTypes.map((item) => (
            <>
              <Col span={12}>
                <Card hoverable onClick={() => selectType(item.type)}>
                  <Flex vertical>
                    <h2>{item.title}</h2>
                    <p style={{ color: "#666" }}>{item.description}</p>
                  </Flex>
                </Card>
              </Col>
            </>
          ))}
        </Row>
      </Modal>
    </>
  );
}

export default SegementDetail;
