import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Card,
  Skeleton,
  Avatar,
  Typography,
  Badge,
  Pagination,
  Form,
  DatePicker,
  Input,
  Space,
  Checkbox,
  Table,
  Tag,
  Flex,
} from "antd";

import CustomerTable from "../components/customer/CustomerTable";
import face2 from "../assets/images/face-2.jpg";
import { ColorFactory } from "antd/es/color-picker/color";
import { axios_json } from "../axios";
import responseHandleSrv from "../services/responseHandleSrv";
import { authHeader } from "../services/auth-header";
import { getCurrentUser } from "../services/auth";
import { formatNumberZero } from "../services/function";
import moment from "moment/moment";

function Segment() {
  const { Title, Text } = Typography;
  const { RangePicker } = DatePicker;
  const [loadings, setLoadings] = useState(false);
  const [preload, setPreload] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectionType, setSelectionType] = useState("checkbox");
  const [lists, setList] = useState([]);
  const [form] = Form.useForm();
  const dateFormat = "DD/MM/YYYY";
  const [pageData, setPageData] = useState({
    currentPage: 1,
    pageSize: 10,
    totalPage: "",
    previousPage: "",
    nextPage: "",
    totalcount: "",
    goToPage: "",
  });
  const [filter, setFilter] = useState({
    search: "",
  });
  useEffect(() => {
    onClear();
    setSelectedRowKeys([]);
  }, []);

  useEffect(() => {
    getData(pageData.currentPage, pageData.pageSize, filter.search);
    console.log("currentPage", pageData.currentPage);
  }, [pageData.currentPage, pageData.pageSize, filter.search]);
  const getData = (page, pageSize, search) => {
    console.log("pageSize", pageSize);

    (async () => {
      setPreload(true);
      await axios_json
        .get(`/api/segments/${pageSize}/${page}`, {
          headers: authHeader(),
          params: {
            "search": search,
          },
        })
        .then((response) => {
          console.log("response", response);
          if (response.data.success) {
            let res = response.data.data;
            let rows = [];
            setPageData((val) => ({
              ...val,
              totalCount: response.data.totalRows,
              currentPage: page,
              totalPage: Math.ceil(response.data.totalRows / pageSize),
            }));
            res.map((item) => {
              // console.log("item", item);
              rows.push({
                id: item.segment_id,
                name: item.name,
                alias: item.alias,
                status: item.is_active,
                customer: item.total,
              });
            });
            setList(rows);
            console.log("list", lists);
            setRowData(res.result);            
          }
          setPreload(false);
        })
        .catch((err) => {
          setPreload(false);
          responseHandleSrv.handleError(err);
        });
    })();
  };
  const onChangePage = (page, pageSize) => {
    setPageData((val) => ({
      ...val,
      currentPage: page,
      pageSize: pageSize,
    }));
  };
  const onClear = () => {
    form.resetFields();
    setFilter((val) => ({
      ...val,
      search: "",
    }));
  };
  const onFinish = (values) => {
    console.log("values", values);
    let search = values.search !== undefined ? values.search : "";
    setFilter((val) => ({
      ...val,
      search: search,
    }));
    setPageData((val) => ({
      ...val,
      currentPage: 1,
    }));
  };

  const header = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "25%",
      render: (text, record) => (
        <>
          <p>
            <Text>{text}</Text>
          </p>
        </>
      ),
    },
    {
      title: "Alias",
      key: "alias",
      dataIndex: "alias",
      render: (text, record) => (
        <>
          <div>
            <Tag>{text}</Tag>
          </div>
        </>
      ),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (text, record) => (
        <>
          {text === 1 ? (
            <div>
              <Tag color="green">Active</Tag>
            </div>
          ) : (
            <div>
              <Tag color="red">Inactive</Tag>
            </div>
          )}
        </>
      ),
    },
    {
      title: "Customer",
      key: "customer",
      dataIndex: "customer",
      width: "15%",
      render: (text, record) => (
        <>
          <Text type="success">{text}</Text>
        </>
      ),
    },

    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      render: (text, record) => (
        <>
          <Link to={`/segment/${record.id}`}>
            <Button type="primary">Edit</Button>
          </Link>
        </>
      ),
    },
  ];


  return (
    <>
      {/* <Card title="ค้นหา"> */}
      <Form layout="vertical" form={form} onFinish={onFinish} name="search">
        <Row gutter={[5, 5]} align={`bottom`}>
          <Col span={6}>
            <Form.Item name={`search`} label={`Search`}>
              <Input
                style={{ color: "black", height: "30px", fontWeight: "400" }}
                placeholder="Segement name"
              />
            </Form.Item>
          </Col>
          {/* <Col span={6}>
            <Form.Item name="range" label="Create Date" >
              <RangePicker format={dateFormat} />
            </Form.Item>
          </Col> */}
          <Col span={4}>
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit" form="search">
                  find
                </Button>
                <Button htmlType="button" onClick={onClear}>
                  clear
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div style={{ fontWeight: "bold" }}>Total Count : {pageData.totalCount}</div>
      {/* </Card> */}
      <Row xs={24} xl={24}>
        <Col xs={24} xl={24}>
          <Card
            title="Segment"
            extra={[
              <Link to="/segment/new">
                <Button type="primary">Add New</Button>
              </Link>,
            ]}
          >
            <div>
              {preload ? (
                <Skeleton active />
              ) : (
                <>
                  {/* <CustomerTable header={header} data={rowData} rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                  }} /> */}
                  <div className="table-responsive">
                    <Table
                      
                      columns={header}
                      rowKey={(record) => record.id}
                      dataSource={lists}
                      pagination={false}
                      className="ant-border-space"
                    />
                  </div>
                  <Pagination
                    total={pageData.totalCount}
                    showSizeChanger
                    showQuickJumper
                    current={pageData.currentPage}
                    defaultPageSize={pageData.pageSize}
                    onChange={onChangePage}
                    responsive={true}
                    className="paginationset"
                  />
                </>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Segment;
