import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Card,
  Skeleton,
  Space,
  Form,
  Input,
  Checkbox,
  Select,
  Upload,
  Table
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import ProductTable from "../../components/product/ProductTable";
import { axios_json } from '../../axios';
import responseHandleSrv from '../../services/responseHandleSrv';
import { authHeader } from "../../services/auth-header";
import { getCurrentUser } from "../../services/auth";
function Role() {
  const [loadings, setLoadings] = useState(false);
  const [preload, setPreload] = useState(false);
  const [roleId, setRoleId] = useState(0);
  const [data, setData] = useState({});
  const [menuList, setMenuList] = useState([]);
  const [pageList, setPageList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [form] = Form.useForm();
  const params = useParams();
  let role_id = params.roleId;

  const saveData = () => {
    setLoadings(true);
    setTimeout(() => {
      console.log("resolve");
      setLoadings(false);
    }, 3000);
  };

  const getMenuList = () => {
    let id = role_id === 'new' ? 0 : role_id;
    (async () => {
      await axios_json.get(`/api/role/menu/${id}`, {
        headers: authHeader()
      }).then(response => {
        if (response.data.success) {
          let res = response.data.data;
          let treeArr = [];
          const parentItems = res.filter((menuItems) => {
            return menuItems.parent === 0;
          });
          let childItem = res.filter((menuItems) => {
            return menuItems.parent !== 0;
          });
          setPageList(childItem);
          parentItems.forEach((menu) => {
            treeArr.push(menu);
            let child = res.filter((menuItems) => {
              return menuItems.parent === menu.id;
            });
            // console.log('child',child);
            if (child.length > 0) {
              child.forEach((ch) => {
                treeArr.push(ch);
              })

            }
          });

          setMenuList(treeArr);
        }

      })
    })();
  };
  useEffect(() => {
    setRoleId(params.roleId);
    if (role_id !== 'new') {
      getData();
    } 

    getMenuList();
    

  }, []);

  
  const getData = () => {

    (async () => {
      // setIsLoading(true)
      setPreload(true);
      await axios_json.get(`/api/role/${role_id}`, {
        headers: authHeader(),
      }).then(response => {
        if (response.data.success) {
          let res = response.data.data;
          console.log('res', res);
          setData(res);

          form.setFieldsValue({
            'role_name': res.name,
          });
        }
        setPreload(false);
      }).catch(err => {
        setPreload(false);
        responseHandleSrv.handleError(err);
      })
    })();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = (values) => {
    console.log('onFinish', values);

    let obj = {
      name: values.role_name !== undefined ? values.role_name : "",
      description: values.description !== undefined ? values.description : "",
      is_active: true,
      list_menu: ""
    };

    
    let checkList_parent = menuList.filter(c => c.check === true && c.parent === 0);
    let checkList_children = menuList.filter(c => c.check === true && c.parent !== 0);

    if(checkList_children.length > 0)
    {
      let parentList = checkList_children.map(p => p.parent);
      
      parentList = parentList.filter(unique);
      let childList = checkList_children.map(c => c.id);
      console.log('parentList',parentList);
      console.log('childList',childList);
      let union = [...parentList, ...childList];
      obj.list_menu = JSON.stringify(union);

    }
    console.log('obj', obj);
   

    if (role_id === 'new') {
      create(obj);
    }
    else {
      update(obj);
    }
  };
  const unique = (value, index, self) => {
    return self.indexOf(value) === index;
}
  const create = (obj) => {
    setLoadings(true);
    (async () => {
      await axios_json.post(`/api/role/create`, obj, {
        headers: authHeader(),

      }).then(response => {
        if (response.data.success) {

          responseHandleSrv.handleSuccess(response);
        }

        setLoadings(false);

      }).catch(err => {
        setLoadings(false)
        responseHandleSrv.handleError(err);
      });
    })();
  };
  const update = (obj) => {
    setLoadings(true);
    (async () => {
      await axios_json.put(`/api/role/${role_id}`, obj, {
        headers: authHeader(),

      }).then(response => {
        if (response.data.success) {

          responseHandleSrv.handleSuccess(response);
        }
        setLoadings(false);

      }).catch(err => {
        setLoadings(false);
        responseHandleSrv.handleError(err);
      });
    })();
  };

  const changeCheckView = (index, e) => {

    let tt = [...menuList];

    let dataRow = tt[index];

    dataRow.check = e.target.checked;

    setMenuList(tt);
  };

  const columns = [
    {
      title: "เมนู",
      key: "name",
      dataIndex: "name",
      render: (text, record, index) => (
        <>
          {record.parent === 0 &&
            <div style={{ fontWeight: "bold", fontSize: "14px" }}>{text}</div>
          }

          {record.parent !== 0 && <div><Checkbox checked={record.check ? record.check : false} onChange={changeCheckView.bind(this, index)}>
            <div>{text}</div>
          </Checkbox></div>}
        </>

        // <>
        // {record.parent === 0 && 
        //     <div style={{ fontWeight: "bold", fontSize: "14px" }}>{text}</div>
        // }

        //     {record.parent !== 0 && <div>{"- "}{text}</div>}
        // </>
      )
    }
  ];

  return (
    <>
      <Row xs={24} xl={24}>
        <Col xs={24} xl={24}>
          <Card
            title={`Role : ${roleId}`}
            extra={[
              <Space>
                <Button type="primary" loading={loadings} form="role" htmlType="submit">
                  Save
                </Button>
              </Space>,
            ]}
            style={{ minHeight: "60vh" }}
          >
            <div>
              {preload ? (
                <Skeleton active />
              ) : (
                <>
                  <Form
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    name="role"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    initialValues={{ remember: true }}
                  >
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="Role"
                          name={"role_name"}
                          rules={[
                            {
                              required: true,
                              message: "Please input role name!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      {/* <Col span={12}>
                        <Form.Item label="Page" name={"page_id"}>
                          <Checkbox.Group>
                            <Row>
                              {pageList && pageList.map((page) => (
                                <Col span={8}>
                                  <Checkbox
                                    value={page.id}
                                    style={{ lineHeight: "32px" }}
                                    checked={page.check !== undefined ? page.check : false}
                                  >
                                    {page.name}
                                  </Checkbox>
                                </Col>
                              ))}
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      </Col> */}
                    </Row>
                  </Form>
                  <Table
                    // rowSelection={rowSelection}
                    columns={columns}
                    dataSource={menuList}
                    pagination={false}
                    // scroll={{ x: 2010 }}
                    rowKey={record => record.id}
                  />
                </>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Role;
