const authHeader = () => {
    let user = localStorage.getItem('user') === null ? '' : JSON.parse(localStorage.user);
    // console.log('user',user);

    if (user && user.token) {
        return { 'Authorization': 'Bearer ' + user.token };
    } else {
        return {};
    }
}

const authBasicHeader = () => {
   
    return { 'Authorization': 'Basic ' + process.env.REACT_APP_BASIC_HEADER };
    
}

export  {
    authHeader,
    authBasicHeader
  };