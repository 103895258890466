import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Card,
  Skeleton,
  Typography,
  Badge,
  Image,
  Space,
  Form,
  Input,
} from "antd";
import ProductTable from "../../components/product/ProductTable";
import face3 from "../../assets/images/face-3.jpg";
import { ColorFactory } from "antd/es/color-picker/color";
import { axios_json } from '../../axios';
import responseHandleSrv from '../../services/responseHandleSrv';
import { authHeader } from "../../services/auth-header";
import { getCurrentUser } from "../../services/auth";

function Category() {
  const { Title, Text } = Typography;
  const [loadings, setIsLoading] = useState(false);
  const [preload, setPreload] = useState(false);
  const [cateId, setCateId] = useState(0);
  const [data, setData] = useState({});
  const [form] = Form.useForm();
  const params = useParams();
  let id = params.cateId;
  const saveData = (values) => {
    setIsLoading(true);
    let data = {
      code: values.code,
      name_en: values.name_en,
      name_th: values.name_th
    };

    console.log('data', data);

    setTimeout(() => {
      (async () => {
        await axios_json.post(`/api/categories/create-or-update`, data , {
          headers: authHeader(),
        }).then(response => {
          if (response.data.success) {

            responseHandleSrv.handleSuccess(response);
          }
          setIsLoading(false)
        }).catch(err => {
          setIsLoading(false)
          responseHandleSrv.handleError(err);
        })
      })();
    }, 3000);

  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    setCateId(id);
    console.log(id);
    if (id !== 'new') {
      getData();
    }
  }, []);

  const getData = () => {

    (async () => {
      // setIsLoading(true)
      setPreload(true);
      await axios_json.get(`/api/categories/${id}`, {
        headers: authHeader(),
      }).then(response => {
        if (response.data.success) {
          let res = response.data.data;
          setData(res);

          form.setFieldsValue({
            'code': res.code,
            'name_th': res.name_th,
            'name_en': res.name_en
          });

        }
        setPreload(false);
      }).catch(err => {
        setPreload(false);
        responseHandleSrv.handleError(err);
      })
    })();
  };
  return (
    <>
      <Row xs={24} xl={24}>
        <Col xs={24} xl={24}>
          <Card
            title={`Categories : ${cateId}`}
            extra={[
              <Button type="primary" form="category" htmlType="submit" loading={loadings}>
                Save
              </Button>,
            ]}
          >
            <div>
              {preload ? (
                <Skeleton active />
              ) : (
                <Form
                  form={form}
                  name="category"
                  onFinish={saveData}
                  onFinishFailed={onFinishFailed}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 20 }}
                  initialValues={{ remember: true }}
                >
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        label={`Code`}
                        name={`code`}
                        rules={[
                          {
                            required: true,
                            message: "Please input code!",
                          },
                        ]}
                      >
                        <Input placeholder="Code" disabled={cateId !== 'new'} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        label={`EN`}
                        name={`name_en`}
                        rules={[
                          {
                            required: true,
                            message: "Please input name en!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label={`TH`}
                        required={true}
                        name={`name_th`}
                        rules={[
                          {
                            required: true,
                            message: "Please input name th!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Category;
