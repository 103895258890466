import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Card,
  Skeleton,
  Space,
  Form,
  Input,
  Select,
  Upload,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import ProductTable from "../../components/product/ProductTable";
import { axios_json } from '../../axios';
import responseHandleSrv from '../../services/responseHandleSrv';
import { authHeader } from "../../services/auth-header";
import { getCurrentUser } from "../../services/auth";

function User() {
  const [loadings, setLoadings] = useState(false);
  const [preload, setPreload] = useState(false);
  const [userId, setUserId] = useState(0);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const [roleList, setRoleList] = useState([]);
  const [form] = Form.useForm();
  const params = useParams();
  let user_id = params.userId;
  const { Option } = Select;
  const { Dragger } = Upload;

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = (values) => {
    console.log('onFinish', values);
    let obj = {
      firstname: values.firstname !== undefined ? values.firstname : "",
      lastname: values.lastname !== undefined ? values.lastname : "",
      mobile_no: values.mobile_no !== undefined ? values.mobile_no : "",
      username: values.username !== undefined ? values.username : "",
      password: values.re_password !== undefined ? values.re_password : "",
      email: values.email !== undefined ? values.email : "",
      role_id: values.role !== undefined ? values.role : "",
      description: values.description !== undefined ? values.description : "",
      is_active: true
    };

    console.log('obj', obj);

    if (user_id === 'new') {
      create(obj);
    }
    else {
      update(obj);
    }
  };


  const create = (obj) => {
    setLoadings(true);
    (async () => {
      await axios_json.post(`/api/user/create`, obj, {
        headers: authHeader(),

      }).then(response => {
        if (response.data.success) {

          responseHandleSrv.handleSuccess(response);
        }

        setLoadings(false);

      }).catch(err => {
        setLoadings(false)
        responseHandleSrv.handleError(err);
      });
    })();
  };
  const update = (obj) => {
    setLoadings(true);
    (async () => {
      await axios_json.put(`/api/user/${user_id}`, obj, {
        headers: authHeader(),

      }).then(response => {
        if (response.data.success) {

          responseHandleSrv.handleSuccess(response);
        }
        setLoadings(false);

      }).catch(err => {
        setLoadings(false);
        responseHandleSrv.handleError(err);
      });
    })();
  };

  useEffect(() => {
    setUserId(params.userId);
    console.log(user_id);
    if (user_id !== 'new') {
      getData();
    }
    getRoleList();
  }, []);
  const getData = () => {

    (async () => {
      // setIsLoading(true)
      setPreload(true);
      await axios_json.get(`/api/user/${user_id}`, {
        headers: authHeader(),
      }).then(response => {
        if (response.data.success) {
          let res = response.data.data;
          console.log('res', res);
          setData(res);

          form.setFieldsValue({
            'firstname': res.firstname,
            'lastname': res.lastname,
            'mobile_no': res.mobile_no,
            'email': res.email,
            'role': res.role_id,
            'username': res.username,
            'description': res.description
          });

        }
        setPreload(false);
      }).catch(err => {
        setPreload(false);
        responseHandleSrv.handleError(err);
      })
    })();
  };
  const getRoleList = () => {
    (async () => {
      await axios_json.get(`/api/role`, {
        headers: authHeader()
      }).then(response => {
        if (response.data.success) {
          setRoleList(response.data.data);
        }

      })
    })();
  };
  return (
    <>
      <Row xs={24} xl={24}>
        <Col xs={24} xl={24}>
          <Card
            title={`User : ${userId}`}
            extra={[
              <Space>
                <Button type="primary" form="user" htmlType="submit" loading={loadings}>
                  Save
                </Button>
              </Space>,
            ]}
          >
            <div>
              {preload ? (
                <Skeleton active />
              ) : (
                <>
                  <Form
                    form={form}
                    name="user"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    initialValues={{ remember: true }}
                  >
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="Firstname"
                          name={"firstname"}
                          rules={[
                            {
                              required: true,
                              message: "Please input firstname!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Lastname"
                          name={"lastname"}
                          rules={[
                            {
                              required: true,
                              message: "Please input lastname!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item label="Mobile" name={"mobile_no"}>
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item label="Email" name={"email"}>
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          name="role"
                          label="Role"
                          rules={[
                            {
                              required: true,
                              message: "Please select role!",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select a option and change input text above"
                            allowClear
                          >
                            {roleList && roleList.map((s) => <Option value={s.id} key={s.id}>{s.name}</Option>)}
                          </Select>
                          {/* <Select
                            placeholder="Select a option and change input text above"
                            allowClear
                          >
                            <Option value="1">Admin</Option>
                            <Option value="2">Product management</Option>
                            <Option value="3">Content management</Option>
                          </Select> */}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          label="Username"
                          name={"username"}
                          rules={[
                            {
                              required: true,
                              message: "Please insert username",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    {user_id === 'new' &&
                      <Row>
                        <Col span={12}>
                          <Form.Item
                            label="Password"
                            name={"password"}
                            rules={[
                              {
                                required: true,
                                message: "Please input password",
                              },
                            ]}
                          >
                            <Input.Password />
                          </Form.Item>
                        </Col>
                      </Row>
                    }
                    {user_id === 'new' &&
                      <Row>
                        <Col span={12}>
                          <Form.Item
                            name="re_password"
                            label="Re-Password"
                            // onChange={inputChange('confirm')}
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                              {
                                required: true,
                                message: 'Please confirm re-password',
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(new Error('รหัสผ่านไม่ตรงกัน'));
                                },
                              }),
                            ]}
                          >
                            <Input.Password placeholder="Re-Password" />
                          </Form.Item>
                        </Col>
                      </Row>
                    }
                    <Row>
                      <Col span={12}>
                        <Form.Item label="Description" name={"description"}>
                          <Input.TextArea rows={4} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default User;
