import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Card,
  Skeleton,
  Typography,
  Badge,
  Image,
  Space,Pagination
} from "antd";
import ProductTable from "../../components/product/ProductTable";
import face3 from "../../assets/images/face-3.jpg";
import { ColorFactory } from "antd/es/color-picker/color";
import { axios_json } from '../../axios';
import responseHandleSrv from '../../services/responseHandleSrv';
import { authHeader } from "../../services/auth-header";
import {getCurrentUser} from "../../services/auth";

function Categories() {
  const { Title, Text } = Typography;
  const [loadings, setIsLoading] = useState(false);
  const [preload, setPreload] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [pageData, setPageData] = useState({
    currentPage: 1,
    pageSize: 10,
    totalPage: '',
    previousPage: '',
    nextPage: '',
    totalcount: '',
    goToPage: ''
  });
  const [filter, setFilter] = useState({
    search: '',
});

function onChangePage(page, pageSize) {

  setPageData((val) => ({
    ...val,
    currentPage: page,
    pageSize : pageSize
  }));

};
useEffect(() => {

  getData(pageData.currentPage, pageData.pageSize, '');

}, [pageData.currentPage,pageData.pageSize]);

const getData = (page, pageSize, search) => {
        
  (async () => {
    setIsLoading(true);
    setPreload(true);
      await axios_json.get(`/api/categories/search/list`, {
          headers: authHeader(),
          params: {
              search,
              page,
              limit : pageSize
          }
      }).then(response => {
        // console.log('response', response );
          if (response.data.success) {
            let res = response.data.data;
            setRowData(res.result);

            setPageData((val) => ({
              ...val,
              totalcount: response.data.data.totalCount
            }));
          }
          setIsLoading(false);
          setPreload(false);
      }).catch(err => {
          setIsLoading(false);
          setPreload(false);
          responseHandleSrv.handleError(err);
      })
  })();
};
  const header = [
    {
      title: "Code",
      key: "code",
      dataIndex: "code",
    },
    {
      title: "Name EN",
      dataIndex: "name_en",
      key: "name_en",
      width: "32%",
    },

    {
      title: "Name TH",
      dataIndex: "name_th",
      key: "name_th",
      width: "32%",
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      render : (text, record) => 
        (
          <>
            <Space>
              <Link to={`/category/${record.code}`}>
                <Button type="primary">Edit</Button>
              </Link>
              {/* <Button type="primary" danger>
                Delete
              </Button> */}
            </Space>
          </>
        ),
      
    },
  ];
  
  return (
    <>
      <Row xs={24} xl={24}>
        <Col xs={24} xl={24}>
          <Card
            title="Categories"
            extra={[
              <Link to="/category/new">
                <Button type="primary">Add New</Button>
              </Link>,
            ]}
          >
            <div>
              {preload ? (
                <Skeleton active />
              ) : (
                <ProductTable header={header} data={rowData} />
              )}
            </div>
            <div>
            <Pagination
                total={pageData.totalcount}
                showSizeChanger
                showQuickJumper
                current={pageData.currentPage}
                // defaultCurrent={1}
                defaultPageSize={pageData.pageSize}
                onChange={onChangePage}
                responsive={true}
                className="paginationset"
              />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Categories;
