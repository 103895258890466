import React from "react";
import { Table, Typography, Pagination } from "antd";
export default function ProductTable({ header, data}) {

  const rows = data || null;
  const columns = header || null;

  return (
    <>
      <div className="table-responsive">
        <Table
          columns={columns}
          dataSource={rows}
          pagination={false}
          // scroll={{ x: 1430 }}
          className="ant-border-space"
        />
        
      </div>
    </>
  );
}
