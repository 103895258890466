import React, { useState, useEffect } from "react";
import { Button, Row, Col, Card, Skeleton } from "antd";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { EditorState, ContentState, convertToRaw } from "draft-js";
// import { convertToHTML } from "draft-convert";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";

function Policy() {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [convertedContent, setConvertedContent] = useState(null);
  const [loadings, setLoadings] = useState(false);
  const [preload, setPreload] = useState(false);

  useEffect(() => {
    let html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setConvertedContent(html);
  }, [editorState]);

  useEffect(() => {
    (async () => {
      try {
        setPreload(true);
        const headers = {
          accept: "application/json",
          Authorization: "Basic " + process.env.REACT_APP_BASIC_HEADER,
        };
        const uri = `${process.env.REACT_APP_API_PRD}/api/content/policy`;
        const res = await axios.get(uri, { headers });
        const iniVal = res.data.data.description;
        const contentBlock = htmlToDraft(iniVal);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          setEditorState(EditorState.createWithContent(contentState));
        } else {
          setEditorState(EditorState.createEmpty());
        }
      } catch (Error) {
        setEditorState(EditorState.createEmpty());
      } finally {
        setPreload(false);
      }
    })();
  }, []);

  const saveData = async () => {
    try {
      setLoadings(true);
      const headers = {
        accept: "application/json",
        "Content-Type": "text/plain",
        Authorization: "Basic " + process.env.REACT_APP_BASIC_HEADER,
      };
      const uri = `${process.env.REACT_APP_API_PRD}/api/content/policy`;
      await axios.put(uri, convertedContent, { headers });
    } finally {
      setLoadings(false);
    }
  };

  return (
    <>
      <Row xs={24} xl={24}>
        <Col xs={24} xl={24}>
          <Card
            title="Policy"
            extra={[
              <Button type="primary" loading={loadings} onClick={saveData}>
                Save
              </Button>,
            ]}
          >
            <div>
              {preload ? (
                <Skeleton active />
              ) : (
                <Editor
                  editorStyle={{ border: "1px solid #f5f7f7", height: "350px" }}
                  editorState={editorState}
                  onEditorStateChange={setEditorState}
                />
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}
export default Policy;
