import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Card,
  Skeleton,
  Avatar,
  Typography,
  Badge,
  Space, Pagination, Modal
} from "antd";
import CustomerTable from "../../components/customer/CustomerTable";
import face2 from "../../assets/images/face-2.jpg";
import { ColorFactory } from "antd/es/color-picker/color";
import { axios_json } from '../../axios';
import responseHandleSrv from '../../services/responseHandleSrv';
import { authHeader } from "../../services/auth-header";
import moment from "moment/moment";

function Roles() {
  const { Title, Text } = Typography;
  const { confirm } = Modal;
  const [loadings, setLoadings] = useState(false);
  const [preload, setPreload] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [pageData, setPageData] = useState({
    currentPage: 1,
    pageSize: 10,
    totalPage: '',
    previousPage: '',
    nextPage: '',
    totalcount: '',
    goToPage: ''
  });
  const [filter, setFilter] = useState({
    search: '',
  });
  useEffect(() => {

    getData(pageData.currentPage, pageData.pageSize, '');

  }, [pageData.currentPage, pageData.pageSize]);

  const getData = (page, pageSize, search) => {

    (async () => {
      setPreload(true)
      await axios_json.get(`/api/role/list`, {
        headers: authHeader(),
        params: {
          search,
          page,
          skip: pageSize
        }
      }).then(response => {

        if (response.data.success) {
          let res = response.data.data;
          setRowData(res.result);

          setPageData((val) => ({
            ...val,
            totalcount: response.data.data.totalCount
          }));
        }
        setPreload(false)
      }).catch(err => {
        setPreload(false)
        responseHandleSrv.handleError(err);
      })
    })();
  };
  const onChangePage = (page, pageSize) => {

    setPageData((val) => ({
      ...val,
      currentPage: page,
      pageSize: pageSize
    }));

  }

  const showModalDelete = (values) => {
    confirm({
      title: 'คุณแน่ใจที่จะลบรายการนี้?',
      okText: 'ใช่',
      cancelText: 'ไม่',
      okType: 'danger',
      centered: true,
      closable: true,
      okButtonProps: { className: 'dangerOutlAnimate' },
      cancelButtonProps: { className: 'radiusBtn' },
      onOk() {
        deleteRole(values);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const deleteRole = (values) => {
    console.log('values', values);

    (async () => {
      setLoadings(true);
      await axios_json.delete(`/api/role/${values.id}`, {
        headers: authHeader()
      }).then(response => {
        if (response.data.success) {
          getData(pageData.currentPage, pageData.pageSize, '');
        }

      }).catch(err => {
        setLoadings(false)
        responseHandleSrv.handleError(err);
      });
    })();
  }

  const header = [
    {
      title: "Role",
      dataIndex: "name",
      key: "name",
      width: "70%",
      render: (text, record) => (
        <>
          <div className="author-info">
            <p>{text}</p>
          </div>
        </>
      )
    },
    {
      title: "Create date",
      key: "create_date",
      dataIndex: "create_date",
      render: (text, record) => (
        <>
          <div className="ant-employed">
            <span>{moment(text).format("DD/MM/YYYY")}</span>
          </div>
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      render: (text, record) => (
        <>
          <Space>
            <Link to={`/role/${record.id}`}>
              <Button type="primary">Edit</Button>
            </Link>
            <Button type="primary" danger onClick={() => showModalDelete(record)}>
              Delete
            </Button>
          </Space>
        </>
      ),
    },
  ];
  const rows = [
    {
      key: "1",

      role: (
        <>
          <div className="author-info">
            <p>Admin</p>
          </div>
        </>
      ),

      createDate: (
        <>
          <div className="ant-employed">
            <span>23/04/18</span>
          </div>
        </>
      ),
      action: (
        <>
          <Space>
            <Link to="/role/1">
              <Button type="primary">Edit</Button>
            </Link>
            <Button type="primary" danger>
              Delete
            </Button>
          </Space>
        </>
      ),
    },
  ];
  return (
    <>
      <Row xs={24} xl={24}>
        <Col xs={24} xl={24}>
          <Card
            title="Roles"
            extra={[
              <Link to="/role/new">
                <Button type="primary">Add New</Button>
              </Link>,
            ]}
          >
            <div>
              {preload ? (
                <Skeleton active />
              ) : (
                <>
                  <CustomerTable header={header} data={rowData} />
                  <Pagination
                    total={pageData.totalcount}
                    showSizeChanger
                    showQuickJumper
                    current={pageData.currentPage}
                    defaultPageSize={pageData.pageSize}
                    onChange={onChangePage}
                    responsive={true}
                    className="paginationset"
                  />
                </>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Roles;
