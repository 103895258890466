import axios from 'axios';
const root = window.location.origin;

console.log('root',root);

let api_url = "";

switch(root) {
  case process.env.REACT_APP_URL_PRD:
    api_url = process.env.REACT_APP_API_PRD;
    break;
  case process.env.REACT_APP_URL_UAT:
    api_url = process.env.REACT_APP_API_UAT;
    break;
  default:
    api_url = process.env.REACT_APP_API_LOCAL;
}

console.log('api_url',api_url);

const axios_json = axios.create({

    baseURL: api_url,
    headers: {
        'Content-Type': 'application/json'
    }
});
const axios_text = axios.create({

    baseURL: api_url,
    headers: {
        'Content-Type': 'application/json'
    }
});

const axios_form = axios.create({

  baseURL: api_url,
  headers: {
      'Content-Type': 'multipart/form-data'
  }
});

export  {
    axios_json,
    axios_text,
    axios_form
};