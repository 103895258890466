import React from "react";
import { Table, Typography } from "antd";
export default function CustomerTable({ header, data }) {
 
  const rows = data || null;
  const columns = header || null;
  
  return (
    <>
      <div className="table-responsive">
        <Table
          columns={columns}
          dataSource={rows}
          pagination={false}
          className="ant-border-space"
        />
      </div>
    </>
  );
}
