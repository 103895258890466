import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Card,
  Skeleton,
  Avatar,
  Typography,
  Badge,
  Space, Pagination, Modal
} from "antd";
import CustomerTable from "../../components/customer/CustomerTable";
import face2 from "../../assets/images/face-2.jpg";
import { ColorFactory } from "antd/es/color-picker/color";
import { axios_json } from '../../axios';
import responseHandleSrv from '../../services/responseHandleSrv';
import { authHeader } from "../../services/auth-header";
import moment from "moment/moment";

function Users() {
  const { Title, Text } = Typography;
  const { confirm } = Modal;
  const [loadings, setLoadings] = useState(false);
  const [preload, setPreload] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [pageData, setPageData] = useState({
    currentPage: 1,
    pageSize: 10,
    totalPage: '',
    previousPage: '',
    nextPage: '',
    totalcount: '',
    goToPage: ''
  });
  const [filter, setFilter] = useState({
    search: '',
  });
  useEffect(() => {

    getData(pageData.currentPage, pageData.pageSize, '');

  }, [pageData.currentPage, pageData.pageSize]);

  const getData = (page, pageSize, search) => {

    (async () => {
      setPreload(true)
      await axios_json.get(`/api/user/search/list`, {
        headers: authHeader(),
        params: {
          search,
          page,
          skip: pageSize
        }
      }).then(response => {
        console.log('response', response);
        if (response.data.success) {
          let res = response.data.data;
          setRowData(res.result);

          setPageData((val) => ({
            ...val,
            totalcount: response.data.data.totalCount
          }));
        }
        setPreload(false)
      }).catch(err => {
        setPreload(false)
        responseHandleSrv.handleError(err);
      })
    })();
  };

  const onChangePage = (page, pageSize) => {

    setPageData((val) => ({
      ...val,
      currentPage: page,
      pageSize: pageSize
    }));

  }

  const displayName = (row) => {
    if (row.firstname != '' && row.lastname != '') {
      return row.firstname + " " + row.lastname;
    } else {
      if (row.firstname != '') {
        return row.firstname
      } else {
        return '';
      }
    }
  };
  const showModalDelete = (values) => {
    confirm({
        title: 'คุณแน่ใจที่จะลบรายการนี้?',
        okText: 'ใช่',
        cancelText: 'ไม่',
        okType: 'danger',
        centered: true,
        closable: true,
        okButtonProps: { className: 'dangerOutlAnimate' },
        cancelButtonProps: { className: 'radiusBtn' },
        onOk() {
          deleteUser(values);
        },
        onCancel() {
            console.log('Cancel');
        },
    });
};

const deleteUser = (values) => {
  console.log('values',values);
  
  (async () => {
    setLoadings(true);
      await axios_json.delete(`/api/user/${values.id}`, {
          headers: authHeader()
      }).then(response => {
          if (response.data.success) {
            getData(pageData.currentPage, pageData.pageSize, '');
          }

      }).catch(err => {
          setLoadings(false)
          responseHandleSrv.handleError(err);
      });
  })();
}


  const header = [
    {
      title: "Name",
      dataIndex: "firstname",
      key: "name",
      width: "32%",
      render: (text, record) => (
        <>
          <Avatar.Group>
            {/* <Avatar
              className="shape-avatar"
              shape="square"
              size={60}
              src={face2}
            ></Avatar> */}
            <div className="avatar-info">
              <Title level={5}>{displayName(record)}</Title>

              <Text level={6} style={{ color: "#1890ff" }}>
                {record.role_name}
              </Text>
            </div>
          </Avatar.Group>
        </>
        // <div>{displayName(record)}</div>
      ),
    },

    {
      title: "Email",
      key: "email",
      dataIndex: "email",
      render: (text, record) => (
        <div className="author-info">
          <p>{text}</p>
        </div>
      )
    },
    {
      title: "Create date",
      key: "createDate",
      dataIndex: "create_date",
      render: (text, record) => (
        <>
          <div className="ant-employed">
            <span>{moment(text).format("DD/MM/YYYY")}</span>
          </div>
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      render: (text, record) => (
        <>
          <Space>
            <Link to={`/user/${record.id}`}>
              <Button type="primary">Edit</Button>
            </Link>
            <Button type="primary" danger onClick={() => showModalDelete(record)}>
              Delete
            </Button>
          </Space>
        </>
      ),
    },
  ];
  const rows = [
    {
      key: "1",
      name: (
        <>
          <Avatar.Group>
            <Avatar
              className="shape-avatar"
              shape="square"
              size={60}
              src={face2}
            ></Avatar>
            <div className="avatar-info">
              <Title level={5}>Michael John</Title>

              <Text level={6} style={{ color: "#1890ff" }}>
                Admin
              </Text>
            </div>
          </Avatar.Group>
        </>
      ),
      email: (
        <>
          <div className="author-info">
            <p>michael@mail.com</p>
          </div>
        </>
      ),

      createDate: (
        <>
          <div className="ant-employed">
            <span>23/04/18</span>
          </div>
        </>
      ),
      action: (
        <>
          <Space>
            <Link to="/user/1">
              <Button type="primary">Edit</Button>
            </Link>
            <Button type="primary" danger>
              Delete
            </Button>
          </Space>
        </>
      ),
    },
  ];
  return (
    <>
      <Row xs={24} xl={24}>
        <Col xs={24} xl={24}>
          <Card title="Users"
            extra={[
              <Link to="/user/new">
                <Button type="primary">Add New</Button>
              </Link>,
            ]}
          >
            <div>
              {preload ? (
                <Skeleton active />
              ) : (
                <>
                  <CustomerTable header={header} data={rowData} />
                  <Pagination
                    total={pageData.totalcount}
                    showSizeChanger
                    showQuickJumper
                    current={pageData.currentPage}
                    defaultPageSize={pageData.pageSize}
                    onChange={onChangePage}
                    responsive={true}
                    className="paginationset"
                  />
                </>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Users;
