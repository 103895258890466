import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/";
import {
  Typography,
  Row,
  Col,
  Card,
  Button,
  Skeleton,
  Form,
  Select,
  Input,
  DatePicker,
  Radio,
  Switch,
} from "antd";
import dayjs from "dayjs";
import { axios_json } from '../axios';
import responseHandleSrv from '../services/responseHandleSrv';
import { authHeader } from "../services/auth-header";
import { getCurrentUser } from "../services/auth";
import moment from "moment/moment";

function CamapignDetail() {
  const { Title } = Typography;
  const { Option } = Select;
  let params = useParams();
  console.log(params);
  let camapign_id = params.campaignId;
  const [loadings, setLoadings] = useState(false);
  const [preload, setPreload] = useState(false);
  const [campaignId, setCampaignId] = useState(0);
  const dateFormat = "DD/MM/YYYY";
  const [form] = Form.useForm();
  const [data, setData] = useState({});
  const [segments, setSegments] = useState([])
  const [segment, setSegment] = useState('')
  const [segmentLabel, setSegmentlabel] = useState('')
  const [nonExpire, setNonExpire] = useState(false)


  useEffect(() => {
    setCampaignId(params.camapign_id);
    if (camapign_id != 'new') {
      getData();
    } else {
      form.setFieldsValue({
        'active': true,
      });

    }
  }, [camapign_id]);

  useEffect(() => {
    getSegments();
  }, []);

  const getData = (segments) => {

    (async () => {
      // setIsLoading(true)
      setPreload(true);
      await axios_json.get(`/api/campaign/${camapign_id}`, {
        headers: authHeader(),
      }).then(response => {
        if (response.data.success) {
          let res = response.data.data;
          console.log('res', res);
          // const selectedOption = segments.find(option => option.segment_id === parseInt(res.segments));
          //let startdate = dayjs(res.startdate).format("DD/MM/YYYY hh:mm:ss")
          setData(res);
          // setSegment(res.segments);

          form.setFieldsValue({
            'campaign': res.name,
            'alias': res.alias,
            'tags': res.tags,
            'active': res.is_active,
          });
          if (res.segments) {
            let comma = res.segments.includes(',');
            if (comma) {
              let sp = res.segments.split(',')
              let arr = [];
              for (let i = 0; i < sp.length; i++) {
                arr.push(Number(sp[i]));
              }
              form.setFieldsValue({
                'segments': arr,
              });

            } else {
              form.setFieldsValue({
                'segments': Number([res.segments]),
              });
            }

          }

          if (res.startdate) {
           
            form.setFieldsValue({
              'startdate': dayjs(res.startdate, 'YYYY-MM-DD'),
            });

          }

          if (res.enddate) {
            form.setFieldsValue({
              'enddate': dayjs(res.enddate, 'YYYY-MM-DD'),
            });
          }

          if(res.startdate == null && res.enddate == null) {
            setNonExpire(true);
            form.setFieldsValue({
              'exipire': true,
            });
          }
        }


      }).catch(err => {
        setPreload(false);
        responseHandleSrv.handleError(err);
      })
    })();
  };

  const getSegments = () => {

    (async () => {
      setPreload(true);
      await axios_json.get(`/api/segments`, {
        headers: authHeader(),
      }).then(response => {
        if (response.data.success) {
          let res = response.data.data;
          setSegments(res)
          // if (camapign_id !== 'new') {
          //   getData(res);
          // }
        }
        setPreload(false);
      }).catch(err => {
        setPreload(false);
        responseHandleSrv.handleError(err);
      })
    })();
  }

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const changeNonExpire = (value) => {
    console.log('changeNonExpire', value)
    setNonExpire(value)
  }
  const onFinish = (values) => {
    console.log('onFinish', values);
    console.log(data)
    let start = '';
    let end = '';
    if (!values.exipire) {
      if (values.startdate) {
        start = values.startdate != undefined ? moment(values.startdate.format('YYYY-MM-DD')).utcOffset(0, true).hours(0).minutes(0).seconds(0).milliseconds(0).format() : '';
      }

      if (values.enddate) {
        end = values.enddate != undefined ? moment(values.enddate.format('YYYY-MM-DD')).utcOffset(0, true).hours(0).minutes(0).seconds(0).milliseconds(0).format() : '';
      }
    }

    let obj = {
      id: data.id,
      name: values.campaign,
      alias: values.alias,
      tags: values.tags,
      is_active: values.active,
      segments: values.segments,
      start_date: start,
      end_date: end

    }
    console.log('obj', obj)
    if (camapign_id !== 'new') {
      update(obj);
    } else {
      create(obj)

    }

  };
  const update = (obj) => {
    console.log('update', obj);
    setLoadings(true);
    (async () => {
      await axios_json.put(`/api/campaign/${camapign_id}`, obj, {
        headers: authHeader(),

      }).then(response => {
        if (response.data.success) {

          responseHandleSrv.handleSuccess(response);
        }
        setLoadings(false);

      }).catch(err => {
        setLoadings(false);
        responseHandleSrv.handleError(err);
      });
    })();
  };

  const create = (obj) => {
    console.log('create', obj);
    setLoadings(true);
    (async () => {
      await axios_json.post(`/api/campaign/create`, obj, {
        headers: authHeader(),

      }).then(response => {
        if (response.data.success) {
          console.log('success', response);
          responseHandleSrv.handleSuccess(response);
        }
        setLoadings(false);

      }).catch(err => {
        setLoadings(false);
        responseHandleSrv.handleError(err);
      });
    })();
  };

  const segmentChange = (obj) => {
    console.log('segmentChange', obj);
    setSegment(obj)
  }

  return (
    <>
      <Row xs={24} xl={24}>
        <Col xs={24} xl={24}>
          <Card
            title="Campaign"
            extra={[
              <Button type="primary" loading={loadings} form="campaign" htmlType="submit">
                Save
              </Button>,
            ]}
          >
            <div>
              {preload ? (
                <Skeleton active />
              ) : (
                <>
                  {camapign_id !== 'new' ? <p>id : {camapign_id}</p> : ''}

                  <Form
                    {...{ labelCol: { span: 4 }, wrapperCol: { span: 20 } }}
                    form={form}
                    name="campaign"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                  >
                    <Row justify="center" gutter={[0, 10]}>
                      <Col span={22}>
                        <div>
                          <Form.Item
                            name="campaign"
                            label={
                              <label
                                style={{ fontWeight: "bold", fontSize: "14px" }}
                              >
                                Campaign
                              </label>
                            }
                            rules={[
                              {
                                required: true,
                                message: "campaign",
                              },
                            ]}
                          >
                            <Input placeholder="campaign" />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={22}>
                        <div>
                          <Form.Item
                            name="alias"
                            label={
                              <label
                                style={{ fontWeight: "bold", fontSize: "14px" }}
                              >
                                alias
                              </label>
                            }
                            rules={[
                              {
                                required: true,
                                message: "alias",
                              },
                            ]}
                          >
                            <Input placeholder="alias" />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={22}>
                        <div>
                          <Form.Item
                            name="tags"
                            label={
                              <label
                                style={{ fontWeight: "bold", fontSize: "14px" }}
                              >
                                Campaign Tags
                              </label>
                            }
                            rules={[
                              {
                                required: false,
                                message: "Campaign Tags",
                              },

                            ]}
                          >
                            <Input placeholder="Campaign Tags" />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={22}>
                        <div>
                          <Form.Item
                            name="active"
                            label={
                              <label
                                style={{ fontWeight: "bold", fontSize: "14px" }}
                              >
                                active
                              </label>
                            }
                            rules={[
                              {
                                required: true,
                                message: "Active",
                              },

                            ]}
                          >
                            <Switch defaultValue="true" />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={22}>
                        <div>
                          <Form.Item
                            name="exipire"
                            label={
                              <label
                                style={{ fontWeight: "bold", fontSize: "14px" }}
                              >
                                Non Expire
                              </label>
                            }
                            rules={[
                              {
                                required: false,
                                message: "Non Expire",
                              },

                            ]}
                          >
                            <Switch onChange={changeNonExpire} />
                          </Form.Item>
                        </div>
                      </Col>

                      <Col span={22}>
                        <div>
                          <Form.Item
                            name="startdate"
                            hidden={nonExpire}
                            label={
                              <label
                                style={{ fontWeight: "bold", fontSize: "14px" }}
                              >
                                Campaign Start
                              </label>
                            }
                            rules={[
                              {
                                required: !nonExpire,
                                message: "Start Date",
                              },

                            ]}
                          >
                            <DatePicker
                              format={dateFormat}

                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={22}>
                        <div>
                          <Form.Item
                            name="enddate"
                            hidden={nonExpire}
                            label={
                              <label
                                style={{ fontWeight: "bold", fontSize: "14px" }}
                              >
                                Campaign  End
                              </label>
                            }
                            rules={[
                              {
                                required: !nonExpire,
                                message: " End Date",
                              },

                            ]}
                          >
                            <DatePicker
                              format={dateFormat}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={22}>
                        <div>
                          <Form.Item
                            name="segments"
                            label={
                              <label
                                style={{ fontWeight: "bold", fontSize: "14px" }}
                              >
                                segment
                              </label>
                            }
                            rules={[
                              {
                                required: true,
                                message: "segment",
                              },
                            ]}
                          >
                            <Select
                              mode="multiple"
                              placeholder="Select an option"
                              value={segment}
                              onChange={segmentChange}
                            >
                              {segments && segments.map((s) => <Option value={s.segment_id} key={s.segment_id}>{s.name}</Option>)}
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>

                    </Row>
                  </Form>
                </>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default CamapignDetail;
