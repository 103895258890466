import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Card,
  Skeleton,
  Space,
  Form,
  Input,
  Select,
  Upload,
  Drawer,
  Pagination,
  Radio,
  Image,
  Checkbox,
  Switch,
  Typography,
  Flex,
  DatePicker, TimePicker
} from "antd";
import moment from "moment/moment";
import { InboxOutlined, UploadOutlined, PlusOutlined } from "@ant-design/icons";
import ProductTable from "../../components/product/ProductTable";
import { axios_json, axios_form } from "../../axios";
import responseHandleSrv from "../../services/responseHandleSrv";
import { authHeader } from "../../services/auth-header";
import { getCurrentUser } from "../../services/auth";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { set } from "draft-js/lib/DefaultDraftBlockRenderMap";

function LineBroadcast() {
  const { Title, Text } = Typography;
  const [loadings, setIsLoading] = useState(false);
  const [preload, setPreload] = useState(false);
  const [productId, setProductId] = useState(0);
  const [data, setData] = useState({});
  const params = useParams();
  const { Option } = Select;
  const { Dragger } = Upload;
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [existImageFile, setExistImageFile] = useState([]);
  const [messageTypeValue, setMessageTypeValue] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewRichOpen, setPreviewRichOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewRichImage, setPreviewRichImage] = useState("");
  const [showTextBox, setShowTextBox] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [showFlex, setShowFlex] = useState(false);
  const [showRishVideo, setShowRishVideo] = useState(false);
  const [showRishmessage, setShowRishmessage] = useState(false);
  const [showCardmessage, setShowCardmessage] = useState(false);
  const [checkTypeMessage, setCheckTypeMessage] = useState("");
  const [campaignList, setCampaignList] = useState([]);
  const [broadcastTime, setBroadcastTime] = useState(false);
  const [broadcastData, setBroadcastData] = useState([]);
  const [formBlocks, setFormBlocks] = useState([]);
  const [eleId, setEleId] = useState(1);
  const [imageIndex, setImageIndex] = useState([]);
  const [fileLists, setFileLists] = useState(Array(20).fill([]));
  const [richMessage, setRichMessage] = useState(Array(20).fill([]));

  const dateFormat = "DD/MM/YYYY HH:mm";

  useEffect(() => {
    getCampaigns();
    form.setFieldsValue({
      'sendTime': "now",
    });
    setBroadcastTime(false);
  }, []);

  const getCampaigns = () => {
    (async () => {
      await axios_json
        .get(`/api/campaign`, {
          headers: authHeader(),
        })
        .then((response) => {
          console.log("campaign", response);
          if (response.data.success) {
            setCampaignList(response.data.data);
          }
        });
    })();
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = (values) => {
    // formBlocks.length
    if (formBlocks.length == 0) {
      responseHandleSrv.handleErrorMsg('กรุณาระบุข้อความที่ต้องการส่ง', '');
      return;
    }

    console.log("values", values);
    console.log('fileLists', fileLists);
    console.log('messageTypeValue', messageTypeValue);
    console.log('richMessage',richMessage);
    const userIds = segmentsValue[values.Segment];
    // console.log('userIdstype', userIds)
    let sendDate = '';
    let sendTime = '';
    if (values.settime) {
      let date = values.settime.format('YYYY-MM-DD HH:mm:ss');
      sendDate = moment(date).second(0).format('YYYY-MM-DD HH:mm:ss');
      sendTime = moment(date).format('HH:mm');
      
    }

    let obj = {
      is_now: values.sendTime == 'set' ? false : true,
      send_date: sendDate,
      send_time: sendTime,
      messages: [],
      messageType: messageTypeValue,
      campaign_ids: values.campaign !== undefined ? values.campaign : "",
      files: [],
      link_url : [],
      files_riches : [],
      titles:[]
    };
    let inxOpt = 0;
    let inxLink = 0;
    let inxTitle = 0;
    Object.keys(values).map((key, index) => {
      if (key.indexOf("message") !== -1) {
        obj.messages.push(values[key])
        inxOpt++;
      }
    });

    Object.keys(values).map((key, index) => {
      if (key.indexOf("Url") !== -1) {
        obj.link_url.push(values[key])
        inxLink++;
      }
    });
    Object.keys(values).map((key, index) => {
      if (key.indexOf("title") !== -1) {
        obj.titles.push(values[key])
        inxTitle++;
      }
    });
    console.log('imageIndex', imageIndex);
    if (imageIndex) {
      for (const file of fileLists) {
        console.log(file);
        if (file.length > 0) {
          obj.files.push(file[0].originFileObj);
        }
      }
    }
    if(richMessage.length > 0 ) {
      for (const rich of richMessage) {
        console.log(rich);
        if (rich.length > 0) {
          obj.files_riches.push(rich[0].originFileObj);
        }
      }
    }
    
    
    console.log("obj", obj);

    sendNotiLine(obj);
  };

  const sendNotiLine = (obj) => {
    const upload = new FormData();

    if(obj.messages.length > 0){
      upload.append("messages", JSON.stringify(obj.messages));
    }
    
    upload.append("messageType", JSON.stringify(obj.messageType));
    upload.append("campaign_ids", JSON.stringify(obj.campaign_ids));
    upload.append("is_now", obj.is_now);
    upload.append("send_date", obj.send_date);
    upload.append("send_time", obj.send_time);

    if(obj.link_url.length > 0){
      upload.append("link_url", JSON.stringify(obj.link_url));
    }
    if(obj.titles.length > 0){
      upload.append("titles", JSON.stringify(obj.titles));
    }

    if (obj.files.length > 0) {
      for (const file of obj.files) {
        upload.append("images", file);
      }
    }

    if (obj.files_riches.length > 0) {
      for (const file of obj.files_riches) {
        upload.append("riches", file);
      }
    }

    setIsLoading(true);
    (async () => {
      await axios_form
        .post(`/api/line/narrow-cast`, upload, {
          headers: authHeader(),
        })
        .then((response) => {
          console.log("sendNotiLine", response);
          if (response.data.success) {
            // if (obj.is_now) {
            //   updateProgress(response.data.data)
            // } else {
            responseHandleSrv.handleSuccess(response);
            form.resetFields();
            setFileLists(Array(5).fill([]));
            setCheckTypeMessage("");
            setShowTextBox(true);
            setShowUpload(true);
            setFormBlocks([]);
            form.setFieldsValue({
              'sendTime': "now",
            });
            setBroadcastTime(false);
            setEleId(1);
            setImageIndex([]);
            setMessageTypeValue([])
            setRichMessage([])
            // }

          }
          setIsLoading(false);

        })
        .catch((err) => {
          setIsLoading(false);
          responseHandleSrv.handleError(err);
        });
    })();
  };

  const updateProgress = (obj) => {
    console.log('updateProgress', obj);
    let data = {
      requestId: obj.requestId,
      line_broadcast_id: obj.id
    };
    setIsLoading(true);
    (async () => {
      await axios_json
        .post(`/api/line/update/progress`, data, {
          headers: authHeader(),
        })
        .then((response) => {
          console.log("sendNotiLine", response);
          if (response.data.success) {
            responseHandleSrv.handleSuccess(response);
            form.resetFields();
            setFileLists(Array(5).fill([]));
            setCheckTypeMessage("");
            setShowTextBox(true);
            setShowUpload(true);
            setFormBlocks([]);
            form.setFieldsValue({
              'sendTime': "now",
            });
            setBroadcastTime(false);
            setEleId(1);
            setImageIndex([]);
            setMessageTypeValue([])
            setRichMessage([])
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          responseHandleSrv.handleError(err);
        });
    })();
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const messageType = [
    { id: 1, name: "ข้อความ", value: "text" },
    { id: 2, name: "รูป", value: "image" },
    // { id: 3, name: 'วิดีโอ', value: 'video' },
    // { id: 4, name: 'ริชเมสเสจ', value: 'Rich text' },
    // { id: 5, name: 'ริชวิดีโอ', value: 'Rich video' },
    // { id: 6, name: 'FLEX', value: 'flex' },
    // { id: 7, name: 'การ์ดเมสเสจ', value: 'card' }
  ];

  const segmentsValue = {
    "Special For You!": ["U7ffb0d4955a26c881b566314166ad112"],
    "Segment test": [
      "U7ffb0d4955a26c881b566314166ad112",
      "U3eacb52e36a2f6cbadca0ddaa65a12e2",
      "Uddfba48885569a8b7b03f186b82bd89a",
    ],
    "Happy Birthday MAY": ["U7ffb0d4955a26c881b566314166ad112"],
    "All Members": [
      "Uddfba48885569a8b7b03f186b82bd89a",
      "U44a9c038f5813d91173e37bfe1fc5be3",
      "Ude647c880a74839031e0270242642e7b",
      "U7ffb0d4955a26c881b566314166ad112",
      "Ue53a0774e7349ee39ad431d40b710ec5",
      "U0fcfcae79521350d0ebad99d448f95da",
      "U3eacb52e36a2f6cbadca0ddaa65a12e2",
    ],
  };

  const addBroadcase = (type) => {
    console.log("addBroadcase", type);
    setMessageTypeValue(m => [...m, type]);
    if (type == 'image') {
      setImageIndex(i => [...i, type]);
    }
    cloneFormBlock(type);
  };

  const deleteBroadcast = () => { };

  const CheckBroadcastime = (value) => {

    if (value.target.value === "set") {
      setBroadcastTime(true);
    } else {
      setBroadcastTime(false);
    }
    form.setFieldsValue({
      'settime': "",
    });
  };

  const onChangeFileMockUp = (id, e) => {
    console.log(e, id)
    // console.log('e', e);
    e.file.status = "done"
    let newFileList = [...e.fileList];
    const updatedFileLists = [...fileLists];
    let index = id - 1;
    console.log(index);
    updatedFileLists[index] = newFileList;
    setFileLists(updatedFileLists);
  }

  const onChangeFileRichMessage = (id, e) => {
    console.log(e, id)
    // console.log('e', e);
    e.file.status = "done"
    let newFileList = [...e.fileList];
    const updatedFileLists = [...richMessage];
    let index = id - 1;
    console.log(index);
    updatedFileLists[index] = newFileList;
    setRichMessage(updatedFileLists);
  }

  const onRemoveImageFile = (file) => {
    console.log('onRemoveImageFile', file);

    if (file?.originFileObj === undefined) {

      let index = existImageFile.indexOf(file.uid);

      let mockUp = [...existImageFile];

      mockUp.splice(index, 1);

      setExistImageFile(mockUp);
    }

    form.setFieldsValue({
      'Image': ''
    });
    form.resetFields(['Image'])

    setFileLists('');
  }
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };
  const handleRichPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewRichImage(file.url || file.preview);
    setPreviewRichOpen(true);
  };
  const FormBlock = ({ id, type, index, onDelete }) => (
    <>
      {type === "text" && (
        <Row
          style={{
            marginTop: "20px",
            border: "1px solid #f1f1f1",
            padding: "20px 0",
          }}
        >
          <Col span={20}>
            <Form.Item
              label={`ข้อความ`}
              name={`message${id}`}
              rules={[
                {
                  required: !showTextBox,
                  message: "Please input message",
                },
              ]}
            >
              <TextArea rows={10} />
            </Form.Item>
          </Col>
          <Col span={2} style={{ marginLeft: "20px" }}>
            <Button type="primary" danger onClick={() => onDelete(id, type, index)}>
              Delete
            </Button>
          </Col>
        </Row>
      )}
      {type === "image" && (
        <Row
          style={{
            marginTop: "20px",
            border: "1px solid #f1f1f1",
            padding: "20px 0",
          }}
        >
          <Col span={20}>
            <Form.Item
              label={`รูปภาพ`}
              name={`Image${id}`}
              // ref={fileRef}
              valuePropName={fileLists}
              rules={[
                {
                  required: !showUpload && fileLists[id - 1].length == 0,
                  message: "Please select Image",
                },
              ]}
            >{console.log(fileLists[id - 1])}
              <Dragger
                onPreview={handlePreview.bind(this, id)}
                onChange={onChangeFileMockUp.bind(this, id)}
                listType="picture-card"
                fileList={fileLists[id - 1]}
                // file={imageFile[1]}
                multiple={false}
                maxCount={1}

              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
              </Dragger>
              <p>
                Only JPG, JPEG and PNG files are supported. image
                files can't be larger than 10 MB.
              </p>
              {previewImage && (
                <Image
                  wrapperStyle={{
                    display: "none",
                  }}
                  preview={{
                    visible: previewOpen,
                    onVisibleChange: (visible) =>
                      setPreviewOpen(visible),
                    afterOpenChange: (visible) =>
                      !visible && setPreviewImage(""),
                  }}
                  src={previewImage}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={2} style={{ marginLeft: "20px" }}>
            <Button type="primary" danger onClick={() => onDelete(id, type, index)}>
              Delete
            </Button>
          </Col>
        </Row>
      )}

        {type === "rich-message" && (
        <Row
          style={{
            marginTop: "20px",
            border: "1px solid #f1f1f1",
            padding: "20px 0",
          }}
        >
          <Col span={20}>
          <Form.Item
              label={`Title`}
              name={`title${id}`}
              rules={[
                {
                  required: true,
                  message: "Please input title",
                },
              ]}
              
            >
              <Input placeholder="Add Title" />
            </Form.Item>
            <Form.Item
              label={`รูปภาพ`}
              name={`Image${id}`}
              // ref={fileRef}
              valuePropName={richMessage}
              rules={[
                {
                  required: !showUpload && richMessage[id - 1].length == 0,
                  message: "Please select Image",
                },
              ]}
            >
              <Dragger
                onPreview={handlePreview.bind(this, id)}
                onChange={onChangeFileRichMessage.bind(this, id)}
                listType="picture-card"
                fileList={richMessage[id - 1]}
                // file={imageFile[1]}
                multiple={false}
                maxCount={1}

              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
              </Dragger>
              <p>
                Only JPG, JPEG and PNG files are supported. image
                files can't be larger than 10 MB.
                <br></br>Image sizes: width 1040px × 1040px
              </p>
              {previewImage && (
                <Image
                  wrapperStyle={{
                    display: "none",
                  }}
                  preview={{
                    visible: previewOpen,
                    onVisibleChange: (visible) =>
                      setPreviewOpen(visible),
                    afterOpenChange: (visible) =>
                      !visible && setPreviewImage(""),
                  }}
                  src={previewImage}
                />
              )}
              
            </Form.Item>
            <Col span={20}>
            <Form.Item
              label={`Link Url`}
              name={`Url${id}`}
              rules={[
                {
                  required: true,
                  message: "Please input link url",
                },
              ]}
              
            >
              <Input placeholder="Add Link Url" />
            </Form.Item>
          </Col>
          </Col>
          <Col span={2} style={{ marginLeft: "20px" }}>
            <Button type="primary" danger onClick={() => onDelete(id, type, index)}>
              Delete
            </Button>
          </Col>
        </Row>
      )}

      {type === "cardmessage" && (
        <Row
          style={{
            marginTop: "20px",
            border: "1px solid #f1f1f1",
            padding: "20px 0",
          }}
        >
          <Col span={20}>
            <Form.Item
              label={`Card message`}
              name={`cardmessage`}
              rules={[
                {
                  required: !showFlex,
                  message: "Please input message",
                },
              ]}
            >
              <Dragger>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload. Strictly
                  prohibited from uploading company data or other
                  banned files.
                </p>
              </Dragger>
              
            </Form.Item>
          </Col>
          <Col span={2} style={{ marginLeft: "20px" }}>
            <Button type="primary" danger onClick={() => onDelete(id, type, index)}>
              Delete
            </Button>
          </Col>
        </Row>
      )}

    </>
  );

  const cloneFormBlock = (dataTypes) => {
    console.log("cloneFormBlock");
    console.log(dataTypes)
    console.log('eleId', eleId);
    if (formBlocks.length <= 4) {
      setEleId(eleId + 1);
      setFormBlocks([...formBlocks, { id: eleId, type: dataTypes}]);
    }
  };

  const deleteFormBlock = (id, type, index) => {
    console.log('deleteFormBlock', id, type, index);
    const updateFormBlock = [...formBlocks];
    updateFormBlock.splice(index, 1);
    // setFormBlocks(formBlocks.filter((block) => block.id !== id));
    setFormBlocks(updateFormBlock);
    const messages = [...messageTypeValue];
    messages.splice(index, 1);
    setMessageTypeValue(messages);

    if (type == 'image') {
      const imageType = [...imageIndex];
      imageType.splice(id - 1, 1);
      setImageIndex(messages);

      const updatedFileLists = [...fileLists];
      updatedFileLists[id - 1] = [];
      setFileLists(updatedFileLists);
    }
    if (type == 'rich-message') {
     
      const updatedFileLists = [...richMessage];
      updatedFileLists[id - 1] = [];
      setRichMessage(updatedFileLists);
    }
  };

  return (
    <>
      <Row xs={24} xl={24}>
        <Col xs={24} xl={24}>
          <Card
            title={`Line Broadcast`}
            extra={[
              <Space>
                <Button type="primary" loading={loadings} htmlType="submit" form="broadcast">
                  Send
                </Button>
              </Space>,
            ]}
          >
            <Form
              form={form}
              name="broadcast"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              initialValues={{ remember: true }}
            >
              {/* <Row>
                                <Col span={24} pull={2}>
                                    <Form.Item
                                        label={`ชื่อบรอดแคสต์`}
                                        name={`broadcast_name`}
                                        rules={[
                                            {
                                                required: true,
                                                message: "กรุณาระบุชื่อบรอดแคสต์",
                                            }
                                        ]}
                                    >
                                        <Input placeholder="ชื่อบรอดแคสต์" />
                                    </Form.Item>
                                </Col>
                            </Row> */}

              <Row gutter={[24, 0]}>
                <Col span={24} pull={2}>
                  <Form.Item
                    name={`campaign`}
                    label={`Campaign`}
                    rules={[
                      {
                        required: true,
                        message: "กรุณาเลือก Campaign",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      showSearch
                      placeholder="Select a Campaign"
                      optionFilterProp="label"
                    // onChange={onChangeMessageType}
                    >
                      {campaignList &&
                        campaignList.map((s) => (
                          <Option value={s.id} key={s.id}>
                            {s.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[24, 0]}>
                <Col span={24} pull={2}>
                  <Form.Item name={`sendTime`} label={`เวลาส่ง`}>
                    <Radio.Group
                      defaultValue="now"
                      buttonStyle="solid"
                      onChange={CheckBroadcastime}
                    >
                      <Radio.Button value="now">ส่งเลย</Radio.Button>
                      <Radio.Button value="set">ตั้งเวลา</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              {broadcastTime && (
                <Row gutter={[24, 0]}>
                  <Col span={24} pull={2}>
                    <Form.Item
                      name={`settime`}
                      label={`ตั้งเวลา`}
                      rules={[
                        {
                          required: broadcastTime,
                          message: "กรุณาเลือกวันที่ และเวลา",
                        },
                      ]}
                    >
                      <DatePicker
                        format={dateFormat}
                        showTime
                        style={{ width: "200px" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}

              <Row gap="middle">
                <Col span={16}>
                  <Row>
                    <Col span={22}>
                      <p style={{ marginBottom: "10px", fontSize: "1.2em" }}>
                        ข้อความบรอดแคสต์ ({formBlocks.length}/5)
                      </p>
                      <Flex gap="middle">
                        <Col span={6}>
                          <Button
                            type="dashed"
                            block
                            onClick={() => addBroadcase("text")}
                          >
                            {" "}
                            ข้อความ{" "}
                          </Button>
                        </Col>
                        <Col span={6}>
                          <Button
                            type="dashed"
                            block
                            onClick={() => addBroadcase("image")}
                          >
                            {" "}
                            รูป{" "}
                          </Button>
                        </Col>
                        <Col span={6}>
                          <Button
                            type="dashed"
                            block
                            onClick={() => addBroadcase("rich-message")}
                          >
                            Rich Message
                          </Button>
                        </Col>
                        {/* <Col span={6}>
                          <Button
                            type="dashed"
                            block
                            onClick={() => addBroadcase("cardmessage")}
                          >
                            {" "}
                            การ์ดเมสเสจ{" "}
                          </Button>
                        </Col> */}
                      </Flex>
                    </Col>
                  </Row>
                  {formBlocks.map((block, index) => (
                    <FormBlock
                      key={block.id}
                      id={block.id}
                      type={block.type}
                      index={index}
                      onDelete={deleteFormBlock}
                    />
                  ))}
                </Col>
                {/* <Col span={8}>
                  Preview
                  <div className="chatbot"></div>
                </Col> */}
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default LineBroadcast;
