import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Card,
  Skeleton,
  Typography,
  Badge,
  Image, Pagination, Tag
} from "antd";
// import moment from "moment";
import ProductTable from "../../components/product/ProductTable";
import face3 from "../../assets/images/face-3.jpg";
import { ColorFactory } from "antd/es/color-picker/color";
import { axios_json } from '../../axios';
import responseHandleSrv from '../../services/responseHandleSrv';
import { authHeader } from "../../services/auth-header";
import { getCurrentUser } from "../../services/auth";
import moment from "moment/moment";

function Promotions() {
  let user = getCurrentUser();
  const { Title, Text } = Typography;
  const [loadings, setIsLoading] = useState(false);
  const [lists, setList] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [preload, setPreload] = useState(false);
  const [pageData, setPageData] = useState({
    currentPage: 1,
    pageSize: 10,
    totalPage: '',
    previousPage: '',
    nextPage: '',
    totalcount: '',
    goToPage: ''
  });
  const [filter, setFilter] = useState({
    search: '',
    start_date: '',
    end_date: ''
  });
  useEffect(() => {

    getData(pageData.currentPage, pageData.pageSize, '', '', '');

  }, [pageData.currentPage, pageData.pageSize]);

  function onChangePage(page, pageSize) {

    setPageData((val) => ({
      ...val,
      currentPage: page,
      pageSize: pageSize
    }));

  }

  const getData = (page, pageSize, search, start_date, end_date) => {

    (async () => {
      setIsLoading(true)
      await axios_json.get(`/api/promotion/list`, {
        headers: authHeader(),
        params: {
          search,
          start_date,
          end_date,
          page,
          skip: pageSize
        }
      }).then(response => {
        console.log('response', response);
        if (response.data.success) {
          let res = response.data.data;
          setList(res);
          setRowData(res.result);
          setPageData((val) => ({
            ...val,
            totalcount: response.data.data.totalCount
          }));

        }
        setIsLoading(false)
      }).catch(err => {
        setIsLoading(false)
        responseHandleSrv.handleError(err);
      })
    })();
  };
  const header = [
    {
      title: "Type",
      dataIndex: "type",
      key: "tittypele",
      // width: "32%",
      render: (text, record) => (
        <>
          {text && text == 'new_alive' ? "New Arrival" : "Promotion"}
        </>
      )
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "32%",
    },

    {
      title: "Banner",
      key: "banner",
      dataIndex: "banner",
      render: (text, record) => (
        <>
          {text ? <Image src={text} width={40} /> : ""}
        </>
      )
    },
    {
      title: "start date",
      key: "start_date",
      dataIndex: "start_date",
      render: (text, record) => (
        <>
          <div className="ant-employed">
            <span>{moment(text).format("DD/MM/YYYY")}</span>
          </div>
        </>
      ),
    },
    {
      title: "end date",
      key: "end_date",
      dataIndex: "end_date",
      render: (text, record) => (
        <>
          <div className="ant-employed">
            <span>{moment(text).format("DD/MM/YYYY")}</span>
          </div>
        </>
      ),
    },
    {
      title: "Active",
      key: "is_active",
      dataIndex: "is_active",
      render: (text, record) => (
        <>
          {(text === true) ? <div><Tag color="green">Yes</Tag></div>
            : <div><Tag color="red">No</Tag></div>}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      render: (text, record) => (
        <>
          <Link to={`/promotion/${record.id}`}>
            <Button type="primary">Edit</Button>
          </Link>
        </>
      ),
    },
  ];

  return (
    <>
      <Row xs={24} xl={24}>
        <Col xs={24} xl={24}>
          <Card
            title="Promotions"
            extra={[
              <Link to="/promotion/new">
                <Button type="primary">Add New</Button>
              </Link>,
            ]}
          >
            <div>
              {preload ? (
                <Skeleton active />
              ) : (
                <>
                  {rowData && <ProductTable header={header} data={rowData} />}
                  <Pagination
                    total={pageData.totalcount}
                    showSizeChanger
                    showQuickJumper
                    current={pageData.currentPage}
                    // defaultCurrent={1}
                    defaultPageSize={pageData.pageSize}
                    onChange={onChangePage}
                    responsive={true}
                    className="paginationset"
                  />
                </>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Promotions;
