import React from "react";
import { Flex, Result, Image } from "antd";
import { SmileTwoTone } from "@ant-design/icons";
import { v4 as uuid } from "uuid";
import { Redirect, useLocation } from "react-router-dom";
import joylidaybackend from "../assets/images/joylidaybackend.jpg";

export default function Default() {
  const unique_id = uuid();
  const small_id = unique_id.slice(0, 8);

  const user = localStorage.getItem("user") || null;
  if (!user) {
    return (
      <Redirect
        to={{
          pathname: "/sign-in",
          state: { referrer: `/${small_id}` },
        }}
      />
    );
  }

  return (
    <>
      <Flex justify="center">
        <Image src={joylidaybackend} preview={false} />
      </Flex>
    </>
  );
}
