import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Card,
  Skeleton,
  Avatar,
  Typography,
  Badge,Pagination
} from "antd";
import CustomerTable from "../components/customer/CustomerTable";
import { useParams } from "react-router-dom";
import { axios_json } from '../axios';
import responseHandleSrv from '../services/responseHandleSrv';
import { authHeader } from "../services/auth-header";
import { getCurrentUser } from "../services/auth";
import {formatNumberZero} from "../services/function"
import moment from "moment/moment";

function CustomerHistory() {
  const { Title, Text } = Typography;
  const [loadings, setLoadings] = useState(false);
  const [preload, setPreload] = useState(false);
  const [lists, setList] = useState([]);
  const [rowData, setRowData] = useState([]);
  const params = useParams();
  let customerId = params.customerId;
  console.log('customerId',customerId);
  const [pageData, setPageData] = useState({
    currentPage: 1,
    pageSize: 10,
    totalPage: '',
    previousPage: '',
    nextPage: '',
    totalcount: '',
    goToPage: ''
  });
  const [filter, setFilter] = useState({
    search: '',
  });


  useEffect(() => {
      getData(pageData.currentPage, pageData.pageSize,'');

  }, [pageData.currentPage, pageData.pageSize]);

  const getData = (page, pageSize, search) => {

    (async () => {
      setPreload(true)
      await axios_json.get(`/api/score/history/list/by-customer`, {
        headers: authHeader(),
        params: {
          cust_id : customerId,
          score_type_id : '',
          page,
          skip: pageSize
        }
      }).then(response => {
        console.log('response', response);
        if (response.data.success) {
          let res = response.data.data;
          console.log('res',res);
          setList(res);
          setRowData(res.result);

          setPageData((val) => ({
            ...val,
            totalcount: response.data.data.totalCount
          }));
          // prepareRows(res.result);
        }
        setPreload(false)
      }).catch(err => {
        setPreload(false)
        responseHandleSrv.handleError(err);
      })
    })();
  };
  const onChangePage = (page, pageSize) => {

    setPageData((val) => ({
      ...val,
      currentPage: page,
      pageSize: pageSize
    }));

  }
  const header = [
    {
      title: "Date",
      dataIndex: "transaction_date",
      key: "transaction_date",
      render: (text, record) => (
        <>
          {text ? moment(text).format("DD/MM/YYYY") : ""}
        </>
      )
    },
    {
      title: "Earn/Burn",
      key: "pointType",
      dataIndex: "pointType",
      render: (text, record) => (
        <>
          {record.amount > 0 ? (
            <span>Earn</span>
          ) : (
            <span>Burn</span>
          )}
      </>
      )
    },
    {
      title: "Type",
      key: "score_type_name",
      dataIndex: "score_type_name"
      
    },
    {
      title: "Point",
      key: "amount",
      dataIndex: "amount",
      render: (text, rec) => (
        <>
          {" "}
          {text <= 0 ? (
            <span style={{ color: "red" }}>{formatNumberZero(text)}</span>
          ) : (
            <span style={{ color: "green" }}>{formatNumberZero(text)}</span>
          )}
        </>
      ),
    },

    {
      title: "Branch",
      key: "branch_name_th",
      dataIndex: "branch_name_th",
    },
    {
      title: "Product name | SKU",
      key: "product_name_th",
      dataIndex: "product_name_th",
      render: (text, record) => (
        <>
          {record.amount > 0 ? (
            <span>-</span>
          ) : (
            <span>{text} | {record.sku}</span>
          )}
      </>
      )
    },
  ];
  const rows = [
    {
      key: "1",
      date: (
        <>
          <span>23/04/18</span>
        </>
      ),
      pointType: (
        <>
          <div className="author-info">
            <span>Earn</span>
          </div>
        </>
      ),

      point: 100,
      branch: (
        <>
          <div className="ant-employed">
            <span>BKK</span>
          </div>
        </>
      ),
    },
    {
      key: "1",
      date: (
        <>
          <span>23/04/19</span>
        </>
      ),
      pointType: (
        <>
          <div className="author-info">
            <span>Burn</span>
          </div>
        </>
      ),

      point: -200,
      branch: (
        <>
          <div className="ant-employed">
            <span>BKK</span>
          </div>
        </>
      ),
    },
  ];
  return (
    <>
      <Row xs={24} xl={24}>
        <Col xs={24} xl={24}>
          <Card title="Customers">
            <div>
              {preload ? (
                <Skeleton active />
              ) : (
                <>
                <CustomerTable header={header} data={rowData} />
                <Pagination
                total={pageData.totalcount}
                showSizeChanger
                showQuickJumper
                current={pageData.currentPage}
                // defaultCurrent={1}
                defaultPageSize={pageData.pageSize}
                onChange={onChangePage}
                responsive={true}
                className="paginationset"
              />
              </>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default CustomerHistory;
