import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Card,
  Skeleton,
  Typography,
  Badge,
  Image,
  Space,
  Form,
  Input,
  Upload,
  DatePicker,
  Switch, Select
} from "antd";
import { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import ProductTable from "../../components/product/ProductTable";
import face3 from "../../assets/images/face-3.jpg";
import { ColorFactory } from "antd/es/color-picker/color";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import dayjs from "dayjs";
// import { convertToHTML } from "draft-convert";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { axios_json, axios_form } from '../../axios';
import responseHandleSrv from '../../services/responseHandleSrv';
import { authHeader } from "../../services/auth-header";
import moment from "moment/moment";

function Promotion() {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [convertedContent, setConvertedContent] = useState(null);
  const { Title, Text } = Typography;
  const [loadings, setLoadings] = useState(false);
  const [preload, setPreload] = useState(false);
  const [data, setData] = useState({});
  const [imageFile, setImageFile] = useState([]);
  const [existImageFile, setExistImageFile] = useState([]);
  const [PId, setPId] = useState(0);
  const params = useParams();
  let id = params.promotionId;
  const [form] = Form.useForm();
  const { Dragger } = Upload;
  const { RangePicker } = DatePicker;
  const dateFormat = "DD/MM/YYYY";
  const fileRef = useRef()
  const { Option } = Select;

  useEffect(() => {
    setPId(id);
    let html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setConvertedContent(html);
  }, [editorState]);

  useEffect(() => {
    if (id != 'new') {
      getData();
    } else {
      form.setFieldsValue({
        'active': false,
        'type' : 'promotion'
      });
    }
  }, [id]);


  const getData = () => {

    (async () => {
      setPreload(true)
      await axios_json.get(`/api/promotion/${id}`, {
        headers: authHeader(),
      }).then(response => {
        if (response.data.success) {
          let res = response.data.data;
          console.log('res', res);
          setData(res);
          if (res) {
            form.setFieldsValue({
              'title': res.title,
              'active': res.is_active,
              'type': res.type,
              'link': res.link
            });

            setEditImageFile(res.id, res.banner);
            setEditDescription(res.description);
            setStartAndEndDate(res.start_date, res.end_date);
          }

        }
        setPreload(false)
      }).catch(err => {
        setPreload(false)
        responseHandleSrv.handleError(err);
      })
    })();
  };
  const setEditImageFile = (id, picPath) => {
    if (picPath) {
      let path = picPath.split('/');
      let lh = path.length - 1;
      let arr = [];
      arr.push({
        uid: id,
        name: path[lh],
        status: 'done',
        url: picPath,
        thumbUrl: picPath,
      });
      console.log('arr', arr);
      setImageFile(arr);
      setExistImageFile([id]);
    }

  };
  const setEditDescription = (desc) => {
    const iniVal = desc;
    const contentBlock = htmlToDraft(iniVal);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      setEditorState(EditorState.createWithContent(contentState));
    } else {
      setEditorState(EditorState.createEmpty());
    }
  };
  const setStartAndEndDate = (start, end) => {
    if (!!start) {

      form.setFieldsValue({
        'Period': [dayjs(start, 'YYYY-MM-DD'), dayjs(end, 'YYYY-MM-DD')]
      });
    }
    else {
      form.setFieldsValue({
        'Period': ''
      });

    }

  };
  const onChangeFileMockUp = (e) => {

    console.log('e', e);
    e.file.status = "done"
    setImageFile(e.fileList);

    setExistImageFile([]);
  }

  const onRemoveImageFile = (file) => {
    console.log('onRemoveImageFile', file);

    if (file?.originFileObj === undefined) {

      let index = existImageFile.indexOf(file.uid);

      let mockUp = [...existImageFile];

      mockUp.splice(index, 1);

      setExistImageFile(mockUp);
    }

    form.setFieldsValue({
      'banner': ''
    });
    form.resetFields(['banner'])

    // setImageFile('');

  }
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const onFinish = (values) => {

    if (existImageFile.length === 0 && imageFile.length == 0) {
      responseHandleSrv.handleErrorMsg('กรุณาเลือก banner', '');
      return;
    }

    let startDate = '';
    let endDate = '';

    if (values.Period) {

      startDate = moment(values.Period[0].format('YYYY-MM-DD')).utcOffset(0, true).hours(0).minutes(0).seconds(0).milliseconds(0).format();
      endDate = moment(values.Period[1].format('YYYY-MM-DD')).utcOffset(0, true).hours(0).minutes(0).seconds(0).milliseconds(0).format();
    }

    let obj = {
      title: values.title !== undefined ? values.title : "",
      description: convertedContent != null ? convertedContent : "",
      banner: "",
      start_date: startDate,
      end_date: endDate,
      is_active: values.active !== undefined ? values.active : false,
      is_delete: false,
      type : values.type !== undefined ? values.type : "",
      link : values.link !== undefined ? values.link : "",
      file: ""
    };

    let mockUp = imageFile ? (imageFile[0]?.originFileObj !== undefined ? imageFile[0].originFileObj : '') : '';

    // console.log('mockUp', mockUp);
    if (mockUp) {
      obj.file = mockUp;
    }

    console.log('obj', obj);
    saveData(obj);
  };

  const saveData = (obj) => {
    console.log('saveData', obj);
    const upload = new FormData();

    upload.append('title', obj.title);
    upload.append('description', obj.description);
    upload.append('banner', obj.banner);
    upload.append('start_date', obj.start_date);
    upload.append('end_date', obj.end_date);
    upload.append('is_active', obj.is_active);
    upload.append('is_delete', obj.is_delete);
    upload.append('type', obj.type);
    upload.append('link', obj.link);
    upload.append('file', obj.file);

    if (id == 'new') {
      create(upload);
    }
    else {
      update(upload);
    }
  };

  const create = (upload) => {
    setLoadings(true);
    (async () => {
      await axios_form.post(`/api/promotion/create`, upload, {
        headers: authHeader(),

      }).then(response => {
        if (response.data.success) {

          responseHandleSrv.handleSuccess(response);
        }
        setLoadings(false);

      }).catch(err => {
        setLoadings(false)
        responseHandleSrv.handleError(err);
      });
    })();
  };

  const update = (upload) => {
    setLoadings(true);
    (async () => {
      await axios_form.put(`/api/promotion/${id}`, upload, {
        headers: authHeader(),

      }).then(response => {
        if (response.data.success) {

          responseHandleSrv.handleSuccess(response);
        }
        setLoadings(false);

      }).catch(err => {
        setLoadings(false);
        responseHandleSrv.handleError(err);
      });
    })();
  };

  const props = {
    name: "file",
    mutiple: false,
  };
  return (
    <>
      <Row xs={24} xl={24}>
        <Col xs={24} xl={24}>
          <Card
            title={`Promotion : ${PId}`}
            extra={[
              <Button type="primary" form="Promotion" htmlType="submit" loading={loadings} >
                Save
              </Button>,
            ]}
          >
            <div>
              {preload ? (
                <Skeleton active />
              ) : (
                <Form
                  form={form}
                  name="Promotion"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 20 }}
                  initialValues={{ remember: true }}
                >
                  <Row>
                    <Col span={24} pull={2}>
                      <Form.Item
                        label={`Type`}
                        name={`type`}
                        rules={[
                          {
                            required: true,
                            message: "Please select type",
                          }
                        ]}
                      >
                        <Select defaultValue="promotion" style={{ width: 200 }} >
                          <Option value="promotion">Promotion</Option>
                          <Option value="new_alive">New Arrival</Option>

                          {/* Add more Option components for additional options */}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24} pull={2}>
                      <Form.Item
                        label={`Title`}
                        name={`title`}
                        rules={[
                          {
                            required: true,
                            message: "Please input title",
                          }
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24} pull={2}>
                      <Form.Item
                        label={`Banner`}
                        name={`banner`}
                        ref={fileRef}
                        // valuePropName="imageFile"
                        rules={[
                          {
                            required: (id !== 'new' && existImageFile.length > 0) ? false : true,
                            message: "Please select banner",
                          }
                        ]}
                      >
                        {/* <Dragger {...props}>
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p className="ant-upload-text">
                            Click or drag file to this area to upload
                          </p>
                        </Dragger> */}
                        <Upload
                          listType="picture"
                          maxCount={1}
                          fileList={[...imageFile]}
                          file={imageFile[0]}
                          onChange={onChangeFileMockUp}
                          // className="upload-list-inline"
                          onRemove={onRemoveImageFile}

                        >
                          <div className="btn-upload"><Button icon={<UploadOutlined />}>Upload</Button></div>
                        </Upload>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24} pull={2}>
                      <Form.Item
                        label={`Period`}
                        required={true}
                        name={`Period`}
                        rules={[
                          {
                            required: true,
                            message: "Please select period",
                          }
                        ]}
                      >
                        <RangePicker
                          id={{
                            start: "startInput",
                            end: "endInput",
                          }}
                          format={dateFormat}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24} pull={2}>
                      <Form.Item
                        label={`Link`}
                        name={`link`}
                        rules={[
                          {
                            required: false,
                            message: "Please input link",
                          }
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24} pull={2}>
                      <Form.Item label={`description`} name={`description`}>
                        <Editor
                          editorStyle={{
                            border: "1px solid #f5f7f7",
                            height: "150px",
                          }}
                          editorState={editorState}
                          onEditorStateChange={setEditorState}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24} pull={2}>
                      <Form.Item
                        label={`active`}
                        rules={[
                          {
                            required: true,
                            message: "Please select active",
                          }
                        ]}
                        name={`active`}
                      >
                        <Switch checkedChildren="on" unCheckedChildren="off" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Promotion;
