import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Card,
  Skeleton,
  Avatar,
  Typography,
  Badge, Pagination, Form, DatePicker, Input, Space, Checkbox, Table, Tag, Flex
} from "antd";

import CustomerTable from "../components/customer/CustomerTable";
import face2 from "../assets/images/face-2.jpg";
import { ColorFactory } from "antd/es/color-picker/color";
import { axios_json } from '../axios';
import responseHandleSrv from '../services/responseHandleSrv';
import { authHeader } from "../services/auth-header";
import { getCurrentUser } from "../services/auth";
import { formatNumberZero } from "../services/function";
import moment from "moment/moment";

function Campaign() {
  const { Title, Text } = Typography;
  const { RangePicker } = DatePicker;
  const [loadings, setLoadings] = useState(false);
  const [preload, setPreload] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectionType, setSelectionType] = useState('checkbox');
  const [lists, setList] = useState([]);
  const [form] = Form.useForm();
  const dateFormat = "DD/MM/YYYY";
  const [pageData, setPageData] = useState({
    currentPage: 1,
    pageSize: 10,
    totalPage: '',
    previousPage: '',
    nextPage: '',
    totalcount: '',
    goToPage: ''
  });
  const [filter, setFilter] = useState({
    search: '',
    start: '',
    end: '',

  });
  useEffect(() => {
    onClear()
    setSelectedRowKeys([])

  }, []);

  useEffect(() => {

    getData(pageData.currentPage, pageData.pageSize, filter.search, filter.start, filter.end);
    console.log(filter);

  }, [pageData.currentPage, pageData.pageSize, filter]);
  const getData = (page, pageSize, search, start, end) => {

    (async () => {
      setPreload(true)
      await axios_json.get(`/api/campaign/list`, {
        headers: authHeader(),
        params: {
          search,
          start,
          end,
          page,
          skip: pageSize
        }
      }).then(response => {
        console.log('response', response);
        if (response.data.success) {
          let res = response.data.data;
          setList(res);
          setRowData(res.result);

          setPageData((val) => ({
            ...val,
            totalcount: res.totalCount
          }));
        }
        setPreload(false)
      }).catch(err => {
        setPreload(false)
        responseHandleSrv.handleError(err);
      })
    })();
  };
  const onChangePage = (page, pageSize) => {

    setPageData((val) => ({
      ...val,
      currentPage: page,
      pageSize: pageSize
    }));

  }
  const onClear = () => {
    form.resetFields();
    setFilter((val) => ({
      ...val,
      search: '',
      start: '',
      end: ''
    }));
  };
  const onFinish = (values) => {
    console.log("values", values);
    let search = values.search !== undefined ? values.search : "";
    // let date = values.create_date !== undefined ? values.create_date.format('YYYY-MM-DD') : "";

    let rangePicker = values.range !== undefined ? values.range : "";

    let start_date = "";
    let end_date = "";

    if (rangePicker) {
      start_date = rangePicker[0].format("YYYY-MM-DD");
      end_date = rangePicker[1].format("YYYY-MM-DD");
    }
    setFilter((val) => ({
      ...val,
      search: search,
      start: start_date,
      end: end_date,
    }));
    setPageData((val) => ({
      ...val,
      currentPage: 1
    }));

  };
  const displayName = (row) => {
    if (row.firstname != '' && row.lastname != '') {
      return row.firstname + " " + row.lastname;
    } else {
      if (row.firstname != '') {
        return row.firstname
      } else {
        return '';
      }
    }
  };
  const campaign =
    [
      {
        id: 1,
        campaign: {
          name: "campaign",
          date: "2024-07-01 09:00:00 ~ 2024-07-31 22:00:00",
          segment: "Segment Happy Birthday JUL",
          tag: "Happy Birthday JUL"
        },
        status: true,
        customer: {
          delivering: 20
        },
        delivery: {
          delivered: 50,
          success: 20,
        },
        engagement: {
          failed: 0,
          opened: 20,
          success: 20,
        }

      },
      {
        id: 2,
        campaign: {
          name: "campaign test",
          date: "2024-07-01 09:00:00 ~ 2024-07-31 22:00:00",
          segment: "Segment Happy Birthday JUL",
          tag: "Happy Birthday JUL"
        },
        status: false,
        customer: {
          delivering: 10
        },
        delivery: {
          delivered: 20,
          success: 4,
        },
        engagement: {
          failed: 7,
          opened: 10,
          success: 20,
        }

      }

    ]

  const header = [
    {
      title: "Camapign",
      dataIndex: "camapign",
      key: "camapign",
      width: "25%",
      render: (text, record) => (
        <>
          <div>
            <p style={{ marginBottom: "5px" }}><Text>{record.name}</Text></p>
            {record.startdate && record.enddate ?
              <p><Text type="secondary">{moment(record.startdate).format("DD/MM/YYYY")} - {moment(record.enddate).format("DD/MM/YYYY")}</Text></p>
              : ''
            }

            <p><Tag color="green">{record.tags}</Tag></p>
          </div>
        </>
      )
    },
    /* {
      title: "Message",
      key: "message",
      dataIndex: "Message",
      render: (text, record) => (
        <div className="author-info">
          <p>{text}</p>
        </div>
      )
    }, */
    {
      title: "Active",
      key: "status",
      dataIndex: "status",
      render: (text, record) => (
        <>
          <>
            {(record.is_active === true) ? <div><Tag color="green">Yes</Tag></div>
              : <div><Tag color="red">No</Tag></div>}
          </>
        </>
      )
    },
    {
      title: "Customers",
      key: "customer",
      dataIndex: "customer_count",
      render: (text, record) => (
        <>
          <p></p>
          <p>{text}</p>
        </>
      )
    },
    {
      title: "Delivers",
      key: "audience_count",
      dataIndex: "audience_count",
      width: "15%",
      render: (text, record) => (
        <>
          <p></p>
          <p>{text}</p>
          {/* <Flex justify={'space-between'}>
            <div>
              <p></p>
              <p>Delivered</p>
            </div>
            <div>
              <p><Text type="success"></Text></p>
              <p><Text type="success">Success</Text></p>
            </div>
          </Flex> */}
        </>
      )
    },
    // {
    //   title: "Engages",
    //   key: "engagement",
    //   dataIndex: "engagement",
    //   width: "20%",
    //   render: (text, record) => (
    //     <>
    //       <Flex justify={'space-between'}>
    //         <div>
    //           <p><Text type="danger"></Text></p>
    //           <p><Text type="danger">Failed</Text></p>
    //         </div>
    //         <div>
    //           <p><Text type="warning"></Text></p>
    //           <p><Text type="warning">Opened</Text></p>
    //         </div>
    //         <div>
    //           <p><Text></Text></p>
    //           <p><Text>Clicked</Text></p>
    //         </div>
    //       </Flex>
    //     </>
    //   )
    // },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      render: (text, record) => (
        <>
          <Link to={`/campaign/${record.id}`}>
            <Button type="primary">Edit</Button>
          </Link>
        </>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setSelectedRowKeys(selectedRowKeys)
    },
    getCheckboxProps: (record) => ({
      disabled: record.line_uid === '' && record.is_active === true,
      // Column configuration not to be checked
      name: record.firstname,
    }),
  };

  return (
    <>
      {/* <Card title="ค้นหา"> */}
      <Form layout="vertical" form={form} onFinish={onFinish} name="search">
        <Row gutter={[5, 5]} align={`bottom`}>
          <Col span={6}>
            <Form.Item name={`search`} label={`Search`}>
              <Input
                style={{ color: "black", height: "30px", fontWeight: "400" }}
                placeholder="Campaign name"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="range" label="Create Date" >
              <RangePicker format={dateFormat} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit" form="search">
                  find
                </Button>
                <Button htmlType="button" onClick={onClear}>
                  clear
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div style={{ fontWeight: 'bold' }}>Total Count : {pageData.totalcount}</div>
      {/* </Card> */}
      <Row xs={24} xl={24}>
        <Col xs={24} xl={24}>
          <Card
            title="Campaign"
            extra={[
              <Link to="/campaign/new">
                <Button type="primary">
                  New Campaign
                </Button>
              </Link>,
            ]}
          >
            <div>
              {preload ? (
                <Skeleton active />
              ) : (
                <>
                  <CustomerTable header={header} data={rowData} />
                  <Pagination
                    total={pageData.totalcount}
                    showSizeChanger
                    showQuickJumper
                    current={pageData.currentPage}
                    defaultPageSize={pageData.pageSize}
                    onChange={onChangePage}
                    responsive={true}
                    className="paginationset"
                  />
                </>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Campaign;
