import {
    notification
} from 'antd';
import { logout } from "./auth";


const handleError = (error) => {
    console.log('handleError', error)
    if (error.response) {

        (error.response.data === '') ?

            (error.response.statusText === '') ?
                notification.error({
                    message: 'แจ้งเตือน',
                    description: error.response.status,
                })
                :
                notification.error({
                    message: 'แจ้งเตือน',
                    description: error.response.statusText,
                })
            :
            (error.response.status === 401
                ? logout()
                :
                notification.error({
                    message: 'แจ้งเตือน',
                    description: error.response.data.message,
                })
            )


    } else if (error.request) {
        notification.error({
            message: 'แจ้งเตือน',
            description: error.request,
        });

    } else {

        notification.error({
            message: 'แจ้งเตือน',
            description: error.message,
        });

    }
};
const handleSuccess = (response) => {
    notification.success({
        message: 'Notification',
        description: response.data.message,
    });
}
const handleInfo = (msg) => {
    notification.info({
        message: 'Info',
        description: msg,
        duration: 10
    });
}

const handleMsg = (msg) => {
    notification.success({
        message: 'Notification',
        description: msg,
    });
}
const handleErrorMsg = (msg, errors) => {
    notification.error({
        message: msg,
        description: errors,
    });
}

const onFinishFailed = (values) => {
    const [{ errors }] = values.errorFields
    notification.error({
        message: 'กรอกข้อมูลไม่ครบ',
        description: errors,
    });
};

const onFinishFailedNoti = (values) => {
    const [{ errors }] = values.errorFields
    notification.error({
        message: 'แจ้งเตือน',
        description: errors,
    });
};


export default {
    handleError,
    handleSuccess,
    handleMsg,
    handleErrorMsg,
    onFinishFailed,
    onFinishFailedNoti,
    handleInfo
};