import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/";
import {
  Typography,
  Row,
  Col,
  Card,
  Button,
  Skeleton,
  Form,
  Select,
  Input,
  DatePicker,
  Radio,
} from "antd";
import dayjs from "dayjs";
import { axios_json } from '../axios';
import responseHandleSrv from '../services/responseHandleSrv';
import { authHeader } from "../services/auth-header";
import { getCurrentUser } from "../services/auth";
import moment from "moment/moment";

function CustomerDetail() {
  const { Title } = Typography;
  const { Option } = Select;
  let params = useParams();
  let cust_id = params.customerId;
  const [loadings, setLoadings] = useState(false);
  const [preload, setPreload] = useState(false);
  const [customerId, setCustomerId] = useState(0);
  const dateFormat = "DD/MM/YYYY";
  const [form] = Form.useForm();
  const [data, setData] = useState({});
  const [subList, setSubList] = useState([]);
  const [alertZipCode, setZipCode] = useState(false)
  const [addressObj, setAddressObj] = useState('');


  useEffect(() => {
    setCustomerId(params.customerId);
    getData();
  }, []);

  const getData = () => {

    (async () => {
      // setIsLoading(true)
      setPreload(true);
      await axios_json.get(`/api/customer/search/by-id/${cust_id}`, {
        headers: authHeader(),
      }).then(response => {
        if (response.data.success) {
          let res = response.data.data;
          console.log('res', res);
          setData(res);

          if (res.zipcode) {
            getZipCode(res.zipcode);
          }
          form.setFieldsValue({
            'firstname': res.firstname,
            'lastname': res.lastname,
            'email': res.email,
            // 'birthdate': res.birthdate,
            'sex': res.sex,
            'address1': res.address1,
            'zipcode': res.zipcode,
            'subdistrict_id': res.subdistrict_id,
            'district_name': res.district_name_th,
            'province_name': res.province_name_th,
            'mobile_no': res.mobile_no,
            'username': res.username
          });

          setValueBirthdate(res);
        }
        setPreload(false);
      }).catch(err => {
        setPreload(false);
        responseHandleSrv.handleError(err);
      })
    })();
  };
  const setValueBirthdate = (res) => {

    if (res.birthdate) {
      let date_sp = res.birthdate.split('-');
      let date = date_sp[2] + '/' + date_sp[1] + '/' + date_sp[0];

      form.setFieldsValue({
        'birthdate': dayjs(date, dateFormat)
      });
    } else {
      form.setFieldsValue({
        'birthdate': ''
      });
    }
  }
  const postCodeChange = (e) => {
    if (e.target.value.length === 5) {
      clearAddress();
      getZipCode(e.target.value);
    }
    else {
      setSubList([]);
      clearAddress();
    }
  };
  // const postCodeBlur = (e) => {
  //   if (e.target.value) {
  //     clearAddress();
  //     getZipCode(e.target.value);
  //   }
  //   else {
  //     setSubList([]);
  //     clearAddress();
  //   }
  // };
  const clearAddress = () => {

    form.setFieldsValue({
      'subdistrict_id': null,
      'district_name': '',
      'province_name': ''
    });
    setAddressObj('');
  }
  const subChange = (value) => {
    let find = subList.find(e => e.subdistrict_id == value);
    console.log('find', find);

    if (Object.entries(find).length > 0) {
      setAddressObj(find);
      form.setFieldsValue({
        'district_name': find.district_name_th,
        'province_name': find.province_name_th
      });
    }
  }
  const getZipCode = (zipCode) => {
    console.log(zipCode);
    (async () => {
      await axios_json.get(`/api/address/getAddrByZipCode/${zipCode}`, {
        headers: authHeader()
      }).then(response => {
        console.log('response', response);
        if (response.data.success) {
          setSubList(response.data.data);
        }
      }).catch(err => {
        setZipCode(true);
        setSubList([]);
        responseHandleSrv.handleErrorMsg('เกิดข้อผิดพลาด', 'รหัสไปรษณีย์ไม่ถูกต้อง');

      })
    })();
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = (values) => {
    console.log('onFinish', values);

    let date = values.birthdate.format('YYYY-MM-DD');
    let obj = {
      id: data.id,
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email !== undefined ? values.email : "",
      birthdate: date,
      mobile_no: values.mobile_no,
      username: values.username,
      is_consent: data.is_consent,
      line_id: data.line_id,
      line_uid: data.line_uid,
      sex: values.sex,
      address1: values.address1 !== undefined ? values.address1 : "",
      address2: "",
      district_id: "",
      subdistrict_id: values.subdistrict_id !== (undefined || null) ? values.subdistrict_id : "",
      province_id: "",
      zipcode: values.zipcode !== undefined ? values.zipcode : "",
      description: data.description,
      role_id: data.role_id,
      is_verified_otp: true,
      is_active: true,
      picture_url: data.picture_url ? data.picture_url : ""
    };

    if (addressObj != '') {
      obj.district_id = addressObj.district_id;
      obj.province_id = addressObj.province_id;
    } else {
      obj.district_id = data.district_id;
      obj.province_id = data.province_id;
    }

    console.log('obj', obj);
    update(obj);
  };
  const update = (obj) => {

    setLoadings(true);
    (async () => {
      await axios_json.put(`/api/customer/${cust_id}`, obj, {
        headers: authHeader(),

      }).then(response => {
        if (response.data.success) {

          responseHandleSrv.handleSuccess(response);
        }
        setLoadings(false);

      }).catch(err => {
        setLoadings(false);
        responseHandleSrv.handleError(err);
      });
    })();
  };
  return (
    <>
      <Row xs={24} xl={24}>
        <Col xs={24} xl={24}>
          <Card
            title="Customer"
            extra={[
              <Button type="primary" loading={loadings} form="customer" htmlType="submit">
                Save
              </Button>,
            ]}
          >
            <div>
              {preload ? (
                <Skeleton active />
              ) : (
                <>
                  <p>id : {customerId}</p>

                  <Form
                    {...{ labelCol: { span: 4 }, wrapperCol: { span: 20 } }}
                    form={form}
                    name="customer"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                  >
                    <Row justify="center" gutter={[0, 10]}>
                      <Col span={22}>
                        <div>
                          <Form.Item
                            name="firstname"
                            label={
                              <label
                                style={{ fontWeight: "bold", fontSize: "14px" }}
                              >
                                ชื่อ
                              </label>
                            }
                            rules={[
                              {
                                required: true,
                                message: "ชื่อ",
                              },
                            ]}
                          >
                            <Input placeholder="ชื่อ" />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={22}>
                        <div>
                          <Form.Item
                            name="lastname"
                            label={
                              <label
                                style={{ fontWeight: "bold", fontSize: "14px" }}
                              >
                                นามสกุล
                              </label>
                            }
                            rules={[
                              {
                                required: true,
                                message: "นามสกุล",
                              },
                            ]}
                          >
                            <Input placeholder="นามสกุล" />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={22}>
                        <div>
                          <Form.Item
                            name="email"
                            label={
                              <label
                                style={{ fontWeight: "bold", fontSize: "14px" }}
                              >
                                อีเมล
                              </label>
                            }
                            rules={[
                              {
                                required: false,
                                message: "อีเมล",
                              },
                              {
                                type: "email",
                                message: "รูปแบบ email ไม่ถูกต้อง",
                              },
                            ]}
                          >
                            <Input placeholder="email" />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={22}>
                        <div>
                          <Form.Item
                            name="username"
                            label={
                              <label
                                style={{ fontWeight: "bold", fontSize: "14px" }}
                              >
                                username
                              </label>
                            }
                          >
                            <Input placeholder="username" disabled />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={22}>
                        <div>
                          <Form.Item
                            name="mobile_no"
                            label={
                              <label
                                style={{ fontWeight: "bold", fontSize: "14px" }}
                              >
                                เบอร์โทรศัพท์
                              </label>
                            }
                          >
                            <Input placeholder="เบอร์โทรศัพท์" disabled />
                          </Form.Item>
                        </div>
                      </Col>

                      <Col span={22}>
                        <div>
                          <Form.Item
                            name="birthdate"
                            label={
                              <label
                                style={{ fontWeight: "bold", fontSize: "14px" }}
                              >
                                วันเกิด
                              </label>
                            }
                            rules={[
                              {
                                required: true,
                                message: "วันเกิด",
                              },
                            ]}
                          >
                            <DatePicker
                              format={dateFormat}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </div>
                      </Col>

                      <Col span={22}>
                        <div>
                          <Form.Item
                            name="sex"
                            label={
                              <label
                                style={{ fontWeight: "bold", fontSize: "14px" }}
                              >
                                เพศ
                              </label>
                            }
                            rules={[
                              {
                                required: true,
                                message: "เพศ",
                              },
                            ]}
                          >
                            <Radio.Group>
                              <Radio value="male">ชาย</Radio>
                              <Radio value="female">หญิง</Radio>
                              <Radio value="other">ไม่ระบุ</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={22}>
                        <div>
                          <Form.Item
                            name="address1"
                            label={
                              <label
                                style={{ fontWeight: "bold", fontSize: "14px" }}
                              >
                                ที่อยู่
                              </label>
                            }
                            rules={[
                              {
                                required: false,
                                message: "ที่อยู่",
                              },
                            ]}
                          >
                            <Input placeholder="ที่อยู่" />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={22}>
                        <div>
                          <Form.Item
                            name="zipcode"
                            label={
                              <label
                                style={{ fontWeight: "bold", fontSize: "14px" }}
                              >
                                รหัสไปรษณีย์
                              </label>
                            }
                            rules={[
                              {
                                required: false,
                                message: "กรอกรหัสไปรษณีย์",
                              },
                              {
                                pattern: /^[0-9]+$/,
                                message: "ตัวเลขเท่านั้น",
                              },
                              {
                                min: 5,
                                message: "ความยาว 5 ตัวอักษร",
                              },
                            ]}
                          >
                            <Input onChange={postCodeChange} placeholder="รหัสไปรษณีย์" maxLength={5} />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={22}>
                        <div>
                          <Form.Item
                            name="subdistrict_id"
                            label={
                              <label
                                style={{ fontWeight: "bold", fontSize: "14px" }}
                              >
                                ตำบล/แขวง
                              </label>
                            }
                          >
                            <Select
                              showSearch
                              placeholder="เลือกตำบล/แขวง"
                              style={{ width: "100%" }}
                              onChange={subChange}
                              // onSelect={subChange}
                              disabled={!subList || subList.length == 0}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              {subList && subList.map((s) => <Option value={s.subdistrict_id} key={s.subdistrict_id}>{s.subdistrict_name_th}</Option>)}
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={22}>
                        <div>
                          <Form.Item
                            name="district_name"
                            label={
                              <label
                                style={{ fontWeight: "bold", fontSize: "14px" }}
                              >
                                อำเภอ/เขต
                              </label>
                            }
                            rules={[
                              {
                                required: false,
                                message: "อำเภอ/เขต",
                              },
                            ]}
                          >
                            <Input placeholder="อำเภอ/เขต" disabled />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={22}>
                        <div>
                          <Form.Item
                            name="province_name"
                            label={
                              <label
                                style={{ fontWeight: "bold", fontSize: "14px" }}
                              >
                                จังหวัด
                              </label>
                            }
                            rules={[
                              {
                                required: false,
                                message: "จังหวัด",
                              },
                            ]}
                          >
                            <Input placeholder="จังหวัด" disabled />
                          </Form.Item>

                        </div>
                      </Col>

                      <Col
                        span={22}
                        style={{ textAlign: "center", marginBottom: "10px" }}
                      >
                      </Col>
                    </Row>
                  </Form>
                </>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default CustomerDetail;
