import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Card,
  Skeleton,
  Typography,
  Badge,
  Image, Pagination,Space,Modal
} from "antd";
// import moment from "moment";
import ProductTable from "../../components/product/ProductTable";
import face3 from "../../assets/images/face-3.jpg";
import { ColorFactory } from "antd/es/color-picker/color";
import { axios_json } from '../../axios';
import responseHandleSrv from '../../services/responseHandleSrv';
import { authHeader } from "../../services/auth-header";
import { getCurrentUser } from "../../services/auth";
import moment from "moment/moment";

function Products() {
  let user = getCurrentUser();
  const { Title, Text } = Typography;
  const { confirm } = Modal;
  const [loadings, setIsLoading] = useState(false);
  const [lists, setList] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [preload, setPreload] = useState(false);
  const [pageData, setPageData] = useState({
    currentPage: 1,
    pageSize: 10,
    totalPage: '',
    previousPage: '',
    nextPage: '',
    totalcount: '',
    goToPage: ''
  });
  const [filter, setFilter] = useState({
    search: '',
  });
  useEffect(() => {

    getData(pageData.currentPage, pageData.pageSize, '');

  }, [pageData.currentPage, pageData.pageSize]);

  function onChangePage(page, pageSize) {

    setPageData((val) => ({
      ...val,
      currentPage: page,
      pageSize: pageSize
    }));

  }

  const getData = (page, pageSize, search) => {

    (async () => {
      setPreload(true)
      await axios_json.get(`/api/product/search/list`, {
        headers: authHeader(),
        params: {
          search,
          page,
          skip: pageSize
        }
      }).then(response => {
        console.log('response', response);
        if (response.data.success) {
          let res = response.data.data;
          setList(res);
          setRowData(res.result);

          setPageData((val) => ({
            ...val,
            totalcount: response.data.data.totalCount
          }));
          // prepareRows(res.result);
        }
        setPreload(false)
      }).catch(err => {
        setPreload(false)
        responseHandleSrv.handleError(err);
      })
    })();
  };
  const header = [
    {
      title: "SKU",
      key: "sku",
      dataIndex: "sku",
    },
    {
      title: "Product Name",
      dataIndex: "name_th",
      key: "name",
      width: "32%",
    },

    {
      title: "Image",
      key: "thumb_pic",
      dataIndex: "thumb_pic",
      render: (text, record) =>
      (
        <>
          {text ? <Image src={text} width={40} /> : ''}
        </>
      ),
    },
    {
      title: "Create date",
      key: "create_date",
      dataIndex: "create_date",
      render: (text, record) => (
        <>
          {text ? moment(text).format("DD/MM/YYYY") : ""}
        </>
      )
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      render: (text, record) => (
        // <>
        //   <Link to={`/product/${record.id}`}>
        //     <Button type="primary">Edit</Button>
        //   </Link>
        // </>
        <>
        <Space>
          <Link to={`/product/${record.id}`}>
            <Button type="primary">Edit</Button>
          </Link>
          <Button type="primary" danger onClick={() => showModalDelete(record)}>
            Delete
          </Button>
        </Space>
      </>
      ),
    },
  ];

  const showModalDelete = (values) => {
    confirm({
        title: 'คุณแน่ใจที่จะลบรายการนี้?',
        okText: 'ใช่',
        cancelText: 'ไม่',
        okType: 'danger',
        centered: true,
        closable: true,
        okButtonProps: { className: 'dangerOutlAnimate' },
        cancelButtonProps: { className: 'radiusBtn' },
        onOk() {
          deleteProduct(values);
        },
        onCancel() {
            console.log('Cancel');
        },
    });
};
const deleteProduct = (values) => {
  console.log('values',values);
  
  (async () => {
    setIsLoading(true);
      await axios_json.delete(`/api/product/${values.id}`, {
          headers: authHeader()
      }).then(response => {
          if (response.data.success) {
            getData(pageData.currentPage, pageData.pageSize, '');
          }

      }).catch(err => {
        setIsLoading(false)
          responseHandleSrv.handleError(err);
      });
  })();
}
  const rows = [
    {
      key: "1",
      sku: "D0001",
      name: "Name 001",

      image: <Image src={face3} width={40} />,
      createDate: "23/04/18",
      action: (
        <>
          <Link to="/product/1">
            <Button type="primary">Edit</Button>
          </Link>
        </>
      ),
    },
  ];
  return (
    <>
      <Row xs={24} xl={24}>
        <Col xs={24} xl={24}>
          <Card
            title="Products"
            extra={[
              <Link to="/product/new">
                <Button type="primary">Add New</Button>
              </Link>,
            ]}
          >
            <div>
              {preload ? (
                <Skeleton active />
              ) : (
                <>
                  {rowData && <ProductTable header={header} data={rowData} pageData={pageData} onChangePage={onChangePage} />}
                  <Pagination
                    total={pageData.totalcount}
                    showSizeChanger
                    showQuickJumper
                    current={pageData.currentPage}
                    // defaultCurrent={1}
                    defaultPageSize={pageData.pageSize}
                    onChange={onChangePage}
                    responsive={true}
                    className="paginationset"
                  />
                </>
              )}

            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Products;
