import React from "react";
import { NavLink, Redirect } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { logout } from "../services/auth";
export const SignOut = () => {
  logout();
  return (
    <NavLink key="singout" to="/sing-in">
      sing-in
    </NavLink>
  );
};
