import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Card,
  Skeleton,
  Avatar,
  Typography,
  Badge, Pagination, Form, DatePicker, Input, Space, Checkbox, Table, Tag, Flex, Tooltip
} from "antd";

import CustomerTable from "../../components/customer/CustomerTable";
import face2 from "../../assets/images/face-2.jpg";
import { ColorFactory } from "antd/es/color-picker/color";
import { axios_json } from '../../axios';
import responseHandleSrv from '../../services/responseHandleSrv';
import { authHeader } from "../../services/auth-header";
import { getCurrentUser } from "../../services/auth";
import { formatNumberZero } from "../../services/function";
import moment from "moment/moment";
import dayjs from "dayjs";

function LineDashBoard() {
  const { Title, Text } = Typography;
  const { RangePicker } = DatePicker;
  const [loadings, setLoadings] = useState(false);
  const [preload, setPreload] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectionType, setSelectionType] = useState('checkbox');
  const [lists, setList] = useState([]);
  const [form] = Form.useForm();
  const dateFormat = "DD/MM/YYYY";
  const [pageData, setPageData] = useState({
    currentPage: 1,
    pageSize: 10,
    totalPage: '',
    previousPage: '',
    nextPage: '',
    totalcount: '',
    goToPage: ''
  });
  const [filter, setFilter] = useState({
    search: '',
    start: '',
    end: '',

  });
  useEffect(() => {
    onClear()
    setSelectedRowKeys([])

  }, []);

  useEffect(() => {

    getData(pageData.currentPage, pageData.pageSize, filter.search, filter.start, filter.end);
    console.log(filter);

  }, [pageData.currentPage, pageData.pageSize, filter]);
  const getData = (page, pageSize, search, start, end) => {

    (async () => {
      setPreload(true)
      await axios_json.get(`/api/line/list`, {
        headers: authHeader(),
        params: {
          search,
          start,
          end,
          page,
          skip: pageSize
        }
      }).then(response => {
        console.log('response', response);
        if (response.data.success) {
          let res = response.data.data;
          setList(res);
          setRowData(res.result);

          setPageData((val) => ({
            ...val,
            totalcount: res.totalCount
          }));
        }
        setPreload(false)
      }).catch(err => {
        setPreload(false)
        responseHandleSrv.handleError(err);
      })
    })();
  };
  const onChangePage = (page, pageSize) => {

    setPageData((val) => ({
      ...val,
      currentPage: page,
      pageSize: pageSize
    }));

  }
  const onClear = () => {
    form.resetFields();
    setFilter((val) => ({
      ...val,
      search: '',
      start: '',
      end: ''
    }));
  };
  const onFinish = (values) => {
    console.log("values", values);
    let search = values.search !== undefined ? values.search : "";
    // let date = values.create_date !== undefined ? values.create_date.format('YYYY-MM-DD') : "";

    let rangePicker = values.range !== undefined ? values.range : "";

    let start_date = "";
    let end_date = "";

    if (rangePicker) {
      start_date = rangePicker[0].format("YYYY-MM-DD");
      end_date = rangePicker[1].format("YYYY-MM-DD");
    }
    setFilter((val) => ({
      ...val,
      search: search,
      start: start_date,
      end: end_date,
    }));
    setPageData((val) => ({
      ...val,
      currentPage: 1
    }));

  };
  const msgType = {
    "text": "ข้อความ",
    "image": "รูปภาพ",
    "imagemap": "ริช แมสเซส",
    
  };
  const displayMessageType = (text) => {
    if (text) {
      let array = [];
      if (text.includes(',')) {
        const sp_text = text.split(',');
        sp_text.map(i => {
          array.push(msgType[i]);
        });
        return array.join(' ,');
      } else {
        return msgType[text];
      } 
    } else {
      return '';
    }
  };

  const header = [
    {
      title: "Send Date",
      dataIndex: "send_date",
      key: "send_date",
      width: "20%",
      render: (text, record) => (
        <>
          <div>
            {record.send_date ?
              <p><Text type="secondary">{moment(record.send_date).utc().format("DD/MM/YYYY HH:mm")}</Text></p>
              : ''
            }
          </div>
        </>
      )
    },
    {
      title: "Campaign",
      key: "campagin_name",
      dataIndex: "campagin_name",
      width: "20%",
      render: (text, record) => (
        <>
          <Tooltip
            title={
              <>
                {record.campaigns && record.campaigns.map(i => (
                  <>
                    <div>{i.campagin_name} : {i.audience_count}</div>
                  </>
                ))}

              </>
            }
          >
            <span>{text}</span>
          </Tooltip>
        </>
      )
    },
    {
      title: "Message Type",
      key: "message_type",
      dataIndex: "message_type",
      render: (text, record) => (
        <>
          <p></p>
          <p>{displayMessageType(text)}</p>
        </>
      )
    },
    {
      title: "Target Count",
      key: "target_count",
      dataIndex: "target_count",
      width: "15%",
    },
    {
      title: "Success Count",
      key: "success_count",
      dataIndex: "success_count",
      width: "15%",
    },
    {
      title: "Failure Count",
      key: "failure_count",
      dataIndex: "failure_count",
      width: "15%",
    }
  ];



  return (
    <>
      {/* <Card title="ค้นหา"> */}
      <Form layout="vertical" form={form} onFinish={onFinish} name="search">
        <Row gutter={[5, 5]} align={`bottom`}>
          {/* <Col span={6}>
            <Form.Item name={`search`} label={`Search`}>
              <Input
                style={{ color: "black", height: "30px", fontWeight: "400" }}
                placeholder="Campaign name"
              />
            </Form.Item>
          </Col> */}
          <Col span={6}>
            <Form.Item name="range" label="Send Date" >
              <RangePicker format={dateFormat} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit" form="search">
                  find
                </Button>
                <Button htmlType="button" onClick={onClear}>
                  clear
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div style={{ fontWeight: 'bold' }}>Total Count : {pageData.totalcount}</div>
      {/* </Card> */}
      <Row xs={24} xl={24}>
        <Col xs={24} xl={24}>
          <Card
            title="Line Dashboard"
            extra={[
              <Link to="/line-broadcast">
                <Button type="primary">
                  New Line Broadcast
                </Button>
              </Link>,
            ]}
          >
            <div>
              {preload ? (
                <Skeleton active />
              ) : (
                <>
                  <CustomerTable header={header} data={rowData} />
                  <Pagination
                    total={pageData.totalcount}
                    showSizeChanger
                    showQuickJumper
                    current={pageData.currentPage}
                    defaultPageSize={pageData.pageSize}
                    onChange={onChangePage}
                    responsive={true}
                    className="paginationset"
                  />
                </>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default LineDashBoard;
