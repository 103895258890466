import React from "react";
// import { Navigate } from "react-router-dom";

const tagLabelStatusStore = {
    1: "blue", //Submitted
    2: "gold", //Pending
    3: "red", //Reject
    4: "green", //Approve
    5: "orange", //Cancel
    6: "purple", //Contract
    7: "gray" //UnVerify

}
const sexObject = {
  "female": "หญิง", 
  "male": "ชาย", 
  "other": "ไม่ระบุ",

}
const formatNumberZero = (num, digit) => {
    if (num) {
        return num.toFixed(digit).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
    return 0;
}

// const redirectUrlProfile = () => {
    
//     if (sessionStorage.getItem("line_profile") && sessionStorage.getItem('is_customer') == '1') {
//         alert(1);
//         return <Navigate to="/line/profile" />;
//     } else if (sessionStorage.getItem("user")) {
//         alert(2);
//       return <Navigate to="/profile/home" />;
//     } else {
//       return <Navigate to="/signin" />;
//     }
// }

const redirectProfile = () => {
    const root = window.location.origin;
    if (sessionStorage.getItem("line_profile") && sessionStorage.getItem('is_customer') == '1') {
        window.location.href = `${root}/line/profile`;

    } else if (sessionStorage.getItem("user")) {
      window.location.href = `${root}/profile/home`;
      
    } else {
      window.location.href = `${root}/signin`;
    }
  }

export { 
    tagLabelStatusStore,
    formatNumberZero,
    redirectProfile,
    sexObject
 }




