import { Redirect, useLocation } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { Result, Button } from "antd";

export const Middleware = ({ children }) => {
  const user = localStorage.getItem("user") || null;
  const unique_id = uuid();
  const location = useLocation();
  const { pathname } = location;
  const small_id = unique_id.slice(0, 8);
  const page = pathname.substring(1, pathname.length);
  if (!user) {
    return (
      <Redirect
        to={{
          pathname: "/sign-in",
          state: { referrer: `/${small_id}` },
        }}
      />
    );
  } else {
    console.log(`page : ${page}`);
    let menus = localStorage.getItem("menu_list") || null;
    // console.log(menus);
    if (menus == null) {
      return <Redirect
        to={{
          pathname: "/sign-in",
          state: { referrer: `/${small_id}` },
        }}
      />;
    }
    menus = JSON.parse(menus);
    const allow = menus.filter((e) => {
      return e.route == page;
    });
    if (allow.length == 0) {
      return (
        <Result
          status="403"
          title="403"
          subTitle="Sorry, you are not authorized to access this page."
        />
      );
    } else {
      return children;
    }
  }
};
